import SubTitledImage from 'components/SubtitledImage/subtitled-image';

const Card = ({ children, img }) => {
	if (img) {
		return <SubTitledImage src={img}>{children}</SubTitledImage>;
	}
	return <div>{children}</div>;
};

export default Card;
