import SubtitledImage from 'components/SubtitledImage/subtitled-image';
import OreTerminal from 'sharedAssets/ore-terminal.jpg';
import styles from './selling.module.scss';

const OreSales = () => (
	<div className={styles.sellingCard}>
		<h3>Selling Mineral/Ore</h3>
		<SubtitledImage src={OreTerminal} />
		<p>
			Sometimes you just want to sell your unrefined ore for some quick
			turnaround. Raw minerals/ore can be sold in refineries beside the refining
			terminals for quick aUEC. Selling at these terminals pays out half of what
			refined materials do. At the terminals you'll want to select "Sell" and
			select your ship in the hangar to sell from to make the sale!
		</p>
		<p>Those stations are:</p>
		<ul>
			<li>ARC L1 - Wide Forest Station</li>
			<li>ARC L2 - Lively Pathway Station</li>
			<li>ARC L4 - Faint Glen Station</li>
			<li>CRU L1 - Ambitious Dream Station</li>
			<li>HUR L1 - Green Glade Station</li>
			<li>HUR L2 - Faithful Dream Station</li>
			<li>MIC L1 - Shallow Frontier Station</li>
			<li>MIC L2 - Long Forest Station</li>
			<li>MIC L5 - Modern Icarus Station</li>
		</ul>
	</div>
);

export default OreSales;
