import { Fragment, useState } from 'react';
import { depositCompositions, depositHarvestableLocationsMap } from 'data';

import ComboPicker from 'components/ComboPicker/combo-picker';
import Section from 'components/Section/section';

import styles from './deposit-locations.module.scss';

const numberFormat = new Intl.NumberFormat();

const calculateProbability = (mineableCompositionPart, resourceName) =>
	(mineableCompositionPart.find(
		(e) => e.mineableElement.resourceType.displayName === resourceName,
	)?.probability || 0) /
	mineableCompositionPart.reduce(
		(x, composition) => x + Number(composition.probability),
		0,
	);

const minerals = Object.entries(depositCompositions).reduce(
	(accumulator, [key, value]) => ({
		...accumulator,
		...value.compositionArray.MineableCompositionPart.reduce(
			(a, b) => ({
				...a,
				[b.mineableElement.resourceType.displayName]: {
					...b,
					deposits: [
						...(accumulator?.[b.mineableElement.resourceType.displayName]
							?.deposits || []),
						{
							...value,
							calculatedProbability: calculateProbability(
								value.compositionArray.MineableCompositionPart,
								b.mineableElement.resourceType.displayName,
							),
						},
					],
				},
			}),
			{},
		),
	}),
	{},
);

const alphabatizedMinerals = Object.entries(minerals)
	.map(([name, data]) => ({ name, data }))
	.sort((a, b) => a.name.localeCompare(b.name));

const Types = () => {
	const [currentMineral, setCurrentMineral] = useState(
		alphabatizedMinerals[0].data,
	);

	const aggregateDepositProbabilties = Object.entries(
		currentMineral.deposits.reduce(
			(accumulator, deposit) => ({
				...accumulator,
				[deposit.depositName]: [
					...(accumulator?.[deposit.depositName] || []),
					deposit,
				],
			}),
			{},
		),
	)
		.map(([type, deposits]) => ({
			type,
			deposits,
			percentage: {
				min: Math.min(...deposits.map((d) => d.calculatedProbability)) * 100,
				max: Math.max(...deposits.map((d) => d.calculatedProbability)) * 100,
			},
		}))
		.sort(
			(a, b) =>
				(b.percentage.min + b.percentage.max) / 2 -
				(a.percentage.min + a.percentage.max) / 2,
		);

	const harvestableLocations = Object.values(
		depositHarvestableLocationsMap,
	).reduce((accumulator, location) => {
		const currentHarvestables = location.harvestables.filter(
			({ harvestable }) =>
				currentMineral.deposits
					.map(({ id }) => id)
					.includes(harvestable.composition.id),
		);

		if (currentHarvestables.length) {
			return {
				...accumulator,
				[location.displayName]: currentHarvestables.map(
					({ harvestable: { composition }, relativeProbability }) => {
						const probability =
							composition.compositionArray.MineableCompositionPart.find(
								(part) =>
									part.mineableElement.id === currentMineral.mineableElement.id,
							).probability;
						return { probability, composition };
					},
				),
			};
		}
		return accumulator;
	}, {});

	const sortedHarvestableLocations = Object.entries(harvestableLocations)
		.sort(
			([aKey, aValue], [bKey, bValue]) =>
				bValue.reduce(
					(accumulator, { composition, probability }) =>
						accumulator + probability,
					0,
				) /
					bValue.length -
				aValue.reduce(
					(accumulator, { composition, probability }) =>
						accumulator + probability,
					0,
				) /
					aValue.length,
		)
		.map(([location, deposits]) => ({ location, deposits }));

	return (
		<Section className={styles.layout}>
			<header>
				<h3>Deposit Mineral Finder</h3>
			</header>
			<section className={styles.contentPicker}>
				<ComboPicker
					onSelect={(index) =>
						setCurrentMineral(alphabatizedMinerals[index].data)
					}
				>
					{alphabatizedMinerals.map(({ name }) => (
						<span key={`mineral_${name}`}>{name}</span>
					))}
				</ComboPicker>
			</section>
			<section>
				<h4>Information</h4>
				<dl>
					<dt>Deposit Capacity</dt>
					<dd>
						{`${numberFormat.format(
							currentMineral.minPercentage,
						)}% - ${numberFormat.format(currentMineral.maxPercentage)}%`}
					</dd>
					<dt>Density</dt>
					<dd>
						{numberFormat.format(
							currentMineral.mineableElement.resourceType.densityType
								.densityUnit.gramsPerCubicCentimeter,
						)}
						g/cm³
					</dd>
					<dt>Instability</dt>
					<dd>
						{numberFormat.format(
							currentMineral.mineableElement.elementInstability,
						)}
					</dd>
					<dt>Explosiveness</dt>
					<dd>
						{numberFormat.format(
							currentMineral.mineableElement.elementExplosionMultiplier,
						)}
					</dd>
					<dt>Clustering</dt>
					<dd>
						{numberFormat.format(
							currentMineral.mineableElement.elementClusterFactor,
						)}
					</dd>
					<dt>Resistance</dt>
					<dd>
						{numberFormat.format(
							currentMineral.mineableElement.elementResistance,
						)}
					</dd>
					<dt>Optimal Window Midpoint</dt>
					<dd>
						{numberFormat.format(
							currentMineral.mineableElement.elementOptimalWindowMidpoint,
						)}
					</dd>
					<dt>Optimal Window Randomness</dt>
					<dd>
						{numberFormat.format(
							currentMineral.mineableElement
								.elementOptimalWindowMidpointRandomness,
						)}
					</dd>
					<dt>Optimal Window Thinness</dt>
					<dd>
						{numberFormat.format(
							currentMineral.mineableElement.elementOptimalWindowThinness,
						)}
					</dd>
				</dl>
			</section>
			<section>
				<h4>Deposit Type Mineral Chance</h4>
				<dl>
					{aggregateDepositProbabilties.map(({ percentage, type }) => (
						<Fragment key={`deposit_percentages_${type}`}>
							<dt>{type}</dt>
							<dd>
								{percentage.min === percentage.max
									? `${numberFormat.format(percentage.min)}%`
									: `${numberFormat.format(
											percentage.min,
									  )}% - ${numberFormat.format(percentage.max)}%`}
							</dd>
						</Fragment>
					))}
				</dl>
			</section>
			<section>
				<h4>Location Deposit Probability</h4>
				<dl className="overflow">
					{sortedHarvestableLocations.map(({ location, deposits }, index) => (
						<Fragment key={`location_deposit_${location}_${index}`}>
							<dt>{location}</dt>
							<dd>
								{deposits
									.sort((a, b) => b.probability - a.probability)
									.map((deposit, index) => (
										<div
											key={`location_deposit_percentages_${deposit.composition.key}_${index}`}
										>
											{`${deposit.composition.key.replace(
												/^(\w+)Deposit_(\w+)$/i,
												'$1 : $2',
											)} - ${numberFormat.format(deposit.probability * 100)}%`}
										</div>
									))}
							</dd>
						</Fragment>
					))}
				</dl>
			</section>
		</Section>
	);
};

export default Types;
