import SubtitledImage from 'components/SubtitledImage/subtitled-image';
import CentralBusinessDistrict from 'sharedAssets/central_business_district.png';
import TradeAndDevelopmentDivision from 'sharedAssets/tdd.jpg';

import styles from './selling.module.scss';

const CommoditySales = () => (
	<div className={styles.sellingCard}>
		<h3>Selling Commodities (Processed Ore)</h3>
		<SubtitledImage src={TradeAndDevelopmentDivision} />
		<p>
			The main cities on each planet in the Stanton System have a location where
			you can sell your refined ores! There are terminals in these areas that
			allow players to buy and sell commodities. You'll want to select "Sell"
			and select your ship to sell from and make the sale!
		</p>
		<p>*TDD is short for Trade and Development Division</p>
		<ul>
			<li>TDD - Area 18 (ArcCorp)</li>
			<li>TDD - New Babbage (MicroTech)</li>
			<li>TDD - Orison (Crusader)</li>
			<li>Central Business District - Lorville (Hurston)</li>
		</ul>
		<p>The Trade Terminals in Lorville...</p>
		<SubtitledImage src={CentralBusinessDistrict} />
	</div>
);

export default CommoditySales;
