import Section from 'components/Section/section';

import CType from './CType.png';
import EType from './EType.png';
import MType from './MType.png';
import PType from './PType.png';
import QType from './QType.png';
import SType from './SType.png';

import styles from './asteroid-signatures.module.scss';

const Types = () => (
	<Section className={`${styles.layout} ${styles.types}`}>
		<ul>
			<li>
				<img className={styles.headerImage} src={CType} alt="" />
				<h3>C-Type</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1700</dd>
				</dl>
			</li>
			<li>
				<img className={styles.headerImage} src={EType} alt="" />
				<h3>E-Type</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1900</dd>
				</dl>
			</li>
			<li>
				<img className={styles.headerImage} src={MType} alt="" />
				<h3>M-Type</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1850</dd>
				</dl>
			</li>
			<li>
				<img className={styles.headerImage} src={PType} alt="" />
				<h3>P-Type</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1750</dd>
				</dl>
			</li>
			<li>
				<img className={styles.headerImage} src={QType} alt="" />
				<h3>Q-Type</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1870</dd>
				</dl>
			</li>
			<li>
				<img className={styles.headerImage} src={SType} alt="" />
				<h3>S-Type</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1720</dd>
				</dl>
			</li>
		</ul>
	</Section>
);

export default Types;
