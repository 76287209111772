import { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import About from 'pages/About/about';
import Home from 'pages/Home/home';
import Mining from 'pages/Mining/mining';
import PostMining from 'pages/PostMining/post-mining';
import Socials from 'pages/Socials/socials';
import Asteroids from 'pages/Mining/Locations/Asteroids/asteroids';
import Deposits from 'pages/Mining/Locations/Deposits/deposits';
import Gemstones from 'pages/Mining/Locations/Gemstones/gemstones';

import CommunityLogo from './MadeByTheCommunity_Black.png';

import styles from './app.module.scss';

export const Main = () => {
	const [toggle, toggleMenu] = useState(false);
	return (
		<HelmetProvider>
			<BrowserRouter>
				<main className={styles.layout} id="top">
					<header className={styles.header}>
						<div className={styles.headerWrapper}>
							<div className={styles.logoContainer}>
								<Link className={styles.logo} to="/"></Link>
							</div>
							<div
								className={`${styles.mobileMenu} ${toggle ? styles.open : ''}`}
								onClick={() => {
									toggleMenu(!toggle);
								}}
							>
								Mobile Menu
							</div>
							<nav
								className={`${styles.mainNavigation} ${
									toggle ? styles.open : ''
								}`}
							>
								<ul
									onClick={() => {
										toggleMenu(!toggle);
									}}
								>
									<li className={styles.hasChildren}>
										<Link to="/mining">
											Mining<div className={styles.arrow}></div>
										</Link>
										<ul>
											<li>
												<Link to="/mining/methods/multi">Mining Methods</Link>
											</li>
											<li>
												<Link to="/mining/upgrades">Ship Upgrades</Link>
											</li>
											<li>
												<Link to="/mining/locations/gemstones">
													Gemstone Types &amp; Locations
												</Link>
											</li>
											<li>
												<Link to="/mining/locations/asteroids">
													Asteroid Types &amp; Locations
												</Link>
											</li>
											<li>
												<Link to="/mining/locations/deposits">
													Deposit Types &amp; Locations
												</Link>
											</li>
										</ul>
									</li>
									<li className={styles.hasChildren}>
										<Link to="/post-mining">
											Refining/Selling<div className={styles.arrow}></div>
										</Link>
										<ul>
											<li>
												<Link to="/post-mining/refining">Refining</Link>
											</li>
											<li>
												<Link to="/post-mining/selling">Selling</Link>
											</li>
										</ul>
									</li>
									<li className={styles.hasChildren}>
										<Link to="/about">
											About Dora<div className={styles.arrow}></div>
										</Link>
										<ul>
											<li>
												<Link to="/socials/stars">My Stars</Link>
											</li>
											<li>
												<Link to="/socials/links">Links</Link>
											</li>
										</ul>
									</li>
								</ul>
								<Link to="/socials/twitch" className={styles.twitch}>
									<span>DoraThaaDestroya</span>
									<svg
										alt="twitch"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
									>
										<path d="M391.17,103.47H352.54v109.7h38.63ZM285,103H246.37V212.75H285ZM120.83,0,24.31,91.42V420.58H140.14V512l96.53-91.42h77.25L487.69,256V0ZM449.07,237.75l-77.22,73.12H294.61l-67.6,64v-64H140.14V36.58H449.07Z" />
									</svg>
								</Link>
							</nav>
						</div>
					</header>
					<div className={styles.contentWrapper}>
						<article className={styles.content}>
							<Routes>
								<Route index element={<Home />} />
								<Route path="/mining/*" element={<Mining />} />
								<Route
									path="/mining/locations/asteroids"
									element={<Asteroids />}
								/>
								<Route
									path="/mining/locations/deposits"
									element={<Deposits />}
								/>
								<Route
									path="/mining/locations/gemstones"
									element={<Gemstones />}
								/>
								<Route path="/post-mining/*" element={<PostMining />} />
								<Route path="/socials/*" element={<Socials />} />
								<Route path="/about" element={<About />} />
							</Routes>
						</article>
						<footer className={styles.footer}>
							<span>
								<p>Updated for PU 3.23</p>
								<p>
									&copy; {new Date().getUTCFullYear()} Destroya Industries, Star
									Citizen®, Roberts Space Industries® and Cloud Imperium® are
									registered trademarks of Cloud Imperium Rights LLC.
								</p>
							</span>
							<img src={CommunityLogo} alt="" />
						</footer>
					</div>
				</main>
			</BrowserRouter>
		</HelmetProvider>
	);
};
