import SubtitledImage from 'components/SubtitledImage/subtitled-image';
import AdminTerminal from 'sharedAssets/admin_terminal.jpg';
import OutpostTerminal from 'sharedAssets/outpost_terminal.jpg';

import styles from './selling.module.scss';

const AdminOffices = () => (
	<div className={styles.sellingCard}>
		<h3>Admin Offices (Gems)</h3>
		<SubtitledImage src={AdminTerminal} />
		<p>
			There are a number of Admin Offices throughout the Stanton System where
			you can sell the gemstones you mined! There are terminals in the admin
			offices that allow players to buy and sell. You want to select "Sell" and
			select your backpack or ROC to sell from and make the sale!
		</p>
		<p>
			See the Terminal Locations section below to see all the locations that
			have Admin Offices.
		</p>
		<p>This is what a typical Outpost Terminal looks like...</p>
		<SubtitledImage src={OutpostTerminal} />
	</div>
);

export default AdminOffices;
