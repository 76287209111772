import { NavLink as Link, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Section from 'components/Section/section';
// import SubtitledImage from 'components/SubtitledImage/subtitled-image';

import Links from './Links/links';
import Stars from './Stars/stars';
import Twitch from './Twitch/twitch';

// import SocialsImage from './socials.png';

const LandingPage = () => (
	<>
		<Helmet>
			<link rel="canonical" href="https://www.scminingmom.com/socials" />
			<title>Star Citizen Mining Mom - Socials</title>
		</Helmet>
		<Section padded text>
			<p>
				In the <Link to="stars">Stars</Link> section, you will find you can see
				who helped me make this site possible and have a chance of having your
				name listed in the Stars section along with them!
			</p>
			<p>
				You can check out my <Link to="twitch">Twitch</Link> live stream while
				getting to know the site, utilising the mining calculator or planning
				your next mining trip!
			</p>
			<p>
				In the <Link to="links">Links</Link> section, you will find a list of my
				social media pages, where you can purchase some Destroya Industries
				merchandise, check out who I’m partnered with outside of Twitch and see
				who sponsors my streams!
			</p>
			{/*<SubtitledImage src={SocialsImage} />*/}
		</Section>
	</>
);

const Socials = () => {
	return (
		<Routes>
			<Route path="/" element={<LandingPage />} />
			<Route path="links" element={<Links />} />
			<Route path="stars" element={<Stars />} />
			<Route path="twitch" element={<Twitch />} />
		</Routes>
	);
};

Socials.Navigation = () => (
	<ul>
		<li>
			<Link to="/socials/stars">Stars</Link>
		</li>
		<li>
			<Link to="/socials/twitch">Twitch</Link>
		</li>
		<li>
			<Link to="/socials/links">Links</Link>
		</li>
	</ul>
);

export default Socials;
