import Section from 'components/Section/section';

import Atacamite from './Atacamite.png';
import Felsic from './Felsic.png';
import Gneiss from './Gneiss.png';
import Granite from './Granite.png';
import Igneous from './Igneous.png';
import Obsidian from './Obsidian.png';
import Quartzite from './Quartzite.png';
import Shale from './Shale.png';

import styles from './deposit-signatures.module.scss';

const Types = () => (
	<Section className={`${styles.layout} ${styles.types}`}>
		<ul>
			<li>
				<img className={styles.headerImage} src={Atacamite} alt="" />
				<h3>Atacamite</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1800</dd>
				</dl>
			</li>

			<li>
				<img className={styles.headerImage} src={Felsic} alt="" />
				<h3>Felsic</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1770</dd>
				</dl>
			</li>

			<li>
				<img className={styles.headerImage} src={Gneiss} alt="" />
				<h3>Gneiss</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1840</dd>
				</dl>
			</li>

			<li>
				<img className={styles.headerImage} src={Granite} alt="" />
				<h3>Granite</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1920</dd>
				</dl>
			</li>

			<li>
				<img className={styles.headerImage} src={Igneous} alt="" />
				<h3>Igneous</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1950</dd>
				</dl>
			</li>

			<li>
				<img className={styles.headerImage} src={Obsidian} alt="" />
				<h3>Obsidian</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1790</dd>
				</dl>
			</li>

			<li>
				<img className={styles.headerImage} src={Quartzite} alt="" />
				<h3>Quartzite</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1820</dd>
				</dl>
			</li>

			<li>
				<img className={styles.headerImage} src={Shale} alt="" />
				<h3>Shale</h3>
				<dl>
					<dt>Signature</dt>
					<dd>1730</dd>
				</dl>
			</li>
		</ul>
	</Section>
);

export default Types;
