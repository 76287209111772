const Section = ({
	children,
	className,
	horizontal = false,
	fill = false,
	padded = false,
	panel = false,
	aside = false,
	text = false,
}) => (
	<section
		className={[
			...(className ? [className] : []),
			...(horizontal ? ['horizontal'] : []),
			...(fill ? ['flexFill'] : []),
			...(padded ? ['padded'] : []),
			...(panel ? ['panel'] : []),
			...(aside ? ['aside'] : []),
			...(text ? ['text'] : []),
		].join(' ')}
	>
		{children}
	</section>
);

export default Section;
