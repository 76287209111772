import { Helmet } from 'react-helmet-async';
import GemStoneInfo, {
	Locations as GemStoneLocations,
} from 'components/GemStoneInfo/gem-stone-info';

import Section from 'components/Section/section';

import styles from './gemstones.module.scss';

const Gemstones = () => {
	return (
		<Section className={styles.layout}>
			<Helmet>
				<link
					rel="canonical"
					href="https://www.scminingmom.com/mining/locations/gemstones"
				/>
				<title>Star Citizen Mining Mom - Gemstones Types & Locations</title>
			</Helmet>
			<Section padded>
				<header>
					<h2>Gemstone Types</h2>
				</header>
			</Section>
			<Section>
				<GemStoneInfo />
			</Section>
			<Section className="panel" padded>
				<GemStoneLocations />
			</Section>
		</Section>
	);
};

export default Gemstones;
