import index from './index.json';
import database from './database.json';
import strings from './strings.json';

const cache = {};
const cached = (key, value) => (cache[key] ? cache[key] : (cache[key] = value));
const memoize = (fn) => {
	const cache = {};
	return (...args) => {
		const stringifiedArgs = JSON.stringify(args);
		return (cache[stringifiedArgs] = cache[stringifiedArgs] || fn(...args));
	};
};

const isGuid = (string) =>
	typeof string === 'string' &&
	string.match(/^[{]?[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}[}]?$/i);

const isStringConstant = (string) =>
	typeof string === 'string' && string.match(/^@[0-9a-z_]+?$/i);

const resolveValue = memoize((value) =>
	isGuid(value)
		? database[value] || value
		: isStringConstant(value)
		  ? strings[value.replace('@', '')] || value
		  : value,
);

const resolvedObject = memoize((object) =>
	Array.isArray(object)
		? object.reduce(
				(accumulator, value) => [
					...accumulator,
					resolvedObject(resolveValue(value)),
				],
				[],
		  )
		: typeof object === 'object'
		  ? Object.entries(object).reduce(
					(accumulator, [key, value]) => ({
						...accumulator,
						[key]: resolvedObject(key === 'id' ? value : resolveValue(value)),
					}),
					{},
		    )
		  : object,
);

export const getByPath = (path) => {
	return path
		.split('.')
		.reduce(
			(accumulator, pathPart) => resolvedObject(accumulator?.[pathPart]),
			index,
		);
};

export const entityClassDefinition = getByPath('EntityClassDefinition');

export const signatureComposition = Object.entries(
	getByPath('EntityClassDefinition'),
)
	.map(([key, entity]) => ({
		key,
		signature:
			entity?.Components?.SSCSignatureSystemParams?.radarProperties
				?.baseSignatureParams?.signatures,
		composition: entity?.Components?.MineableParams?.composition,
	}))
	.filter((entry) => entry.signature && entry.composition)
	.reduce(
		(accumulator, { key, ...data }) => ({ ...accumulator, [key]: { ...data } }),
		{},
	);

export const mineralCompositions = cached(
	'mineralCompositions',
	Object.fromEntries(
		Object.entries(getByPath('MineableComposition')).map(([key, value]) => [
			[key],
			{ ...value, key },
		]),
	),
);

export const asteroidCompositions = cached(
	'asteroidCompositions',
	Object.fromEntries(
		Object.entries(getByPath('MineableComposition'))
			.filter(([key]) => key.match(/^Asteroid_[^_]+_.+$/i))
			.map(([key, value]) => [[key], { ...value, key }]),
	),
);

export const depositCompositions = cached(
	'depositCompositions',
	Object.fromEntries(
		Object.entries(getByPath('MineableComposition'))
			.filter(([key]) => key.match(/^[^_]+_.+$/i))
			.filter(([key]) => !key.match(/^Asteroid_.+$/i))
			.filter(([key]) => !key.match(/^FPS_.+$/i))
			.map(([key, value]) => [[key], { ...value, key }]),
	),
);

const shipHarvestableLocations = memoize((compositions) =>
	Object.fromEntries(
		Object.entries(getByPath('HarvestableProviderPreset')).map(
			([key, value]) => {
				const shipMineables = value.harvestableGroups.HarvestableElementGroup
					.length
					? value.harvestableGroups.HarvestableElementGroup.find(
							(harvestable) => harvestable.groupName === 'SpaceShip_Mineables',
					  )
					: value.harvestableGroups.HarvestableElementGroup;
				return [
					[key],
					{
						groupProbability: shipMineables.groupProbability,
						harvestables: (shipMineables.harvestables.HarvestableElement.length
							? shipMineables.harvestables.HarvestableElement
							: [shipMineables.harvestables.HarvestableElement]
						).map(({ clustering, harvestable, relativeProbability }) => ({
							clustering,
							harvestable: {
								respawnInSlotTime: harvestable.respawnInSlotTime,
								composition:
									Object.values(compositions).find(
										(composition) =>
											harvestable.entityClass.Components.MineableParams
												.composition.id === composition.id,
									) ||
									console.error(
										'Compositions missing: ',
										harvestable.entityClass.Components.MineableParams
											.composition.id,
									),
							},
							relativeProbability,
						})),
					},
				];
			},
		),
	),
);

const consolidateHarvestables = memoize((providerPresets) =>
	providerPresets.reduce(
		(accumulator, preset) => [
			...accumulator,
			...preset.harvestables.map((harvestable) => ({
				...harvestable,
				relativeProbability:
					harvestable.relativeProbability * preset.groupProbability,
			})),
		],
		[],
	),
);

export const asteroidHarvestableLocationsMap = {
	aaronHalo: {
		displayName: 'Aaron Halo',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_AaronHalo'],
		]),
	},
	stanton2cBelt: {
		displayName: 'Yela Belt',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton2c_Belt'],
		]),
	},
	stanton1L1A: {
		displayName: 'ARC-L1-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_F'],
		]),
	},
	stanton1L2A: {
		displayName: 'ARC-L2-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_F'],
		]),
	},
	stanton1L3A: {
		displayName: 'ARC-L3-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_D'],
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_E'],
		]),
	},
	stanton1L4A: {
		displayName: 'ARC-L4-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_B'],
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_F'],
		]),
	},
	stanton1L5A: {
		displayName: 'ARC-L5-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_B'],
		]),
	},
	stanton2L1A: {
		displayName: 'CRU-L1-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_E'],
		]),
	},
	stanton2L2A: {
		displayName: 'CRU-L2-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_E'],
		]),
	},
	stanton2L2B: {
		displayName: 'CRU-L2-B',
		harvestables: consolidateHarvestables([]),
	},
	stanton2L2C: {
		displayName: 'CRU-L2-C',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_E'],
		]),
	},
	stanton2L3A: {
		displayName: 'CRU-L3-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_C'],
		]),
	},
	stanton2L4A: {
		displayName: 'CRU-L4-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_B'],
		]),
	},
	stanton2L5A: {
		displayName: 'CRU-L5-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_D'],
		]),
	},
	stanton3L1A: {
		displayName: 'HUR-L1-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_A'],
		]),
	},
	stanton3L2A: {
		displayName: 'HUR-L2-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_F'],
		]),
	},
	stanton3L3A: {
		displayName: 'HUR-L3-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_E'],
		]),
	},
	stanton3L4A: {
		displayName: 'HUR-L4-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_A'],
		]),
	},
	stanton3L5A: {
		displayName: 'HUR-L5-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_A'],
		]),
	},
	stanton4L1A: {
		displayName: 'MIC-L1-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_C'],
		]),
	},
	stanton4L1B: {
		displayName: 'MIC-L1-B',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_C'],
		]),
	},
	stanton4L2A: {
		displayName: 'MIC-L2-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_C'],
		]),
	},
	stanton4L2B: {
		displayName: 'MIC-L2-B',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_C'],
		]),
	},
	stanton4L2C: {
		displayName: 'MIC-L2-C',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_C'],
		]),
	},
	stanton4L3A: {
		displayName: 'MIC-L3-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_B'],
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_E'],
		]),
	},
	stanton4L4A: {
		displayName: 'MIC-L4-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_A'],
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_E'],
		]),
	},
	stanton4L5A: {
		displayName: 'MIC-L5-A',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Lagrange_C'],
		]),
	},
};

export const depositHarvestableLocationsMap = {
	stanton1: {
		displayName: 'Hurston',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton1'],
		]),
	},
	stanton1a: {
		displayName: 'Hurston - Arial',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton1a'],
		]),
	},
	stanton1b: {
		displayName: 'Hurston - Aberdeen',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton1b'],
		]),
	},
	stanton1c: {
		displayName: 'Hurston - Magda',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton1c'],
		]),
	},
	stanton1d: {
		displayName: 'Hurston - Ita',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton1d'],
		]),
	},
	stanton2: {
		displayName: 'Crusader',
		harvestables: consolidateHarvestables([]),
	},
	stanton2a: {
		displayName: 'Crusader - Cellin',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton2a'],
		]),
	},
	stanton2b: {
		displayName: 'Crusader - Daymar',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton2b'],
		]),
	},
	stanton2c: {
		displayName: 'Crusader - Yela',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton2c'],
		]),
	},
	stanton3: {
		displayName: 'ArcCorp',
		harvestables: consolidateHarvestables([]),
	},
	stanton3a: {
		displayName: 'ArcCorp - Lyria',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton3a'],
		]),
	},
	stanton3b: {
		displayName: 'ArcCorp - Wala',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton3b'],
		]),
	},
	stanton4: {
		displayName: 'Microtech',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton4'],
		]),
	},
	stanton4a: {
		displayName: 'Microtech - Calliope',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton4a'],
		]),
	},
	stanton4b: {
		displayName: 'Microtech - Clio',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton4b'],
		]),
	},
	stanton4c: {
		displayName: 'Microtech - Euterpe',
		harvestables: consolidateHarvestables([
			shipHarvestableLocations(mineralCompositions)['HPP_Stanton4c'],
		]),
	},
};
