import { NavLink as Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Section from 'components/Section/section';

const A = ({ ...args }) => (
	<>
		{' '}
		{/* eslint-disable-next-line */}
		<a {...args} />{' '}
	</>
);

const Home = () => {
	return (
		<>
			<Section>
				<header>
					<h2>Welcome to SCMiningMom.com!</h2>
				</header>
			</Section>
			<Section padded text>
				<Helmet>
					<link rel="canonical" href="https://www.scminingmom.com" />
					<title>Star Citizen Mining Mom - Home</title>
				</Helmet>
				<p>
					Whether you're a new miner, hand miner, ROC miner, Prospector miner,
					or MOLE miner, SCminingmom was created with other Star Citizen miners
					in mind!
				</p>
				<p>
					If you're like me and dislike switching from site to site, where you
					come across outdated information, or everything is scattered around,
					hard to find, and in hard to read terms, I have good news for you. You
					don't need to go through various different websites or have a business
					degree any more, because I got your back!
				</p>
				<p>With a user friendly layout, you'll find that you can;</p>
				<ul>
					{/* <li>
						Quickly calculate what your asteroids and/or deposits will yield in
						both scu and refined aUEC
					</li> */}
					<li>Check out and learn about mining lasers, gadgets and modules</li>
					<li>Learn about different mining vessels and techniques</li>
					<li>Mining locations for various asteroids and deposits</li>
					<li>
						Plan your mining trips into the Aaron Halo or planet/moon side
					</li>
					<li>
						See all the different refinery locations in the Stanton System.
					</li>
				</ul>
				<p>
					New to mining? If you have questions, feel free to check out{' '}
					<A href="https://www.twitch.tv/dorathaadestroya">my Twitch Stream</A>{' '}
					and ask me when I'm live! Want some more in depth mining knowledge,
					head over to{' '}
					<A href="https://www.patreon.com/Dorathaadestroya">Patreon</A> where I
					do some 1 on 1 lessons and give you the inside scoop on all my mining
					favourites!
				</p>
				<p>
					Not quite sure where to start? Here's a quick start guide to getting
					you up and ready for some Quantanium mining!
				</p>
				<p>
					The first thing you'll want to do is follow the{' '}
					<Link to="/mining/methods/multi">FPS/Hand mining guide</Link>
				</p>
			</Section>
		</>
	);
};

export default Home;
