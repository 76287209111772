import {
	NavLink as Link,
	Routes,
	Route,
	useLocation,
	//useNavigate,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// import ComboPicker from 'components/ComboPicker/combo-picker';
//import Calculator from 'components/ScuCalculator/scu-calculator';
// import GemStoneInfo from 'components/GemStoneInfo/gem-stone-info';
import Section from 'components/Section/section';

import Multi from './Multi/multi';
import Roc from './Roc/roc';
import Prospector from './Prospector/prospector';
import Mole from './Mole/mole';

import styles from './methods.module.scss';

const miningMethods = [
	{
		fullDescription: 'Greycat Pyro Multi-Tool w/ OreBit Mining Attachment',
		label: 'Greycat Multi-Tool',
		url: '/mining/methods/multi',
	},
	{
		fullDescription: 'Greycat ROC (Remote Ore Collector / Dual Seat)',
		label: 'Greycat ROC / ROC-DS',
		url: '/mining/methods/roc',
	},
	{
		fullDescription: 'MISC Prospector',
		label: 'MISC Prospector',
		url: '/mining/methods/prospector',
	},
	{
		fullDescription: 'Argo MOLE (Multi Operator Laser Extractor)',
		label: 'Argo MOLE',
		url: '/mining/methods/mole',
	},
];

const ShipItems = ({ method }) => (
	<>
		<section className="padded">
			<header>
				<h3>Available Mining Lasers</h3>
			</header>
			<p>
				<Link
					to={`/mining/upgrades?Size=${{ prospector: 1, mole: 2 }[method]}#top`}
				>
					Size {{ prospector: 1, mole: 2 }[method]}
				</Link>
			</p>
		</section>
		{/* <Calculator gridSize={{ prospector: 32, mole: 96 }[method]} /> */}
	</>
);

const Methods = () => {
	const { pathname } = useLocation();
	// const navigate = useNavigate();

	return (
		<Section className={styles.layout}>
			<Helmet>
				<link
					rel="canonical"
					href="https://www.scminingmom.com/mining/methods"
				/>
				<title>Star Citizen Mining Mom - Mining Methods</title>
			</Helmet>
			<section className={styles.contentPicker}>
				<ul>
					{miningMethods.map((method) => (
						<li
							key={method.url}
							className={method.url === pathname ? 'active' : ''}
						>
							<Link to={method.url}>{method.label}</Link>
						</li>
					))}
				</ul>
			</section>

			<Routes>
				<Route index element={<Multi key="Multi" />} />
				<Route path="multi" element={<Multi key="Multi" />} />
				<Route path="roc" element={<Roc />} />
				<Route path="prospector" element={<Prospector />} />
				<Route path="mole" element={<Mole />} />
			</Routes>
			<Routes>
				{/* <Route path="/" element={<GemStoneInfo method="multi" />} />
				<Route path="multi" element={<GemStoneInfo method="multi" />} />
				<Route path="roc" element={<GemStoneInfo method="roc" />} /> */}
				<Route path="prospector" element={<ShipItems method="prospector" />} />
				<Route path="mole" element={<ShipItems method="mole" />} />
			</Routes>
		</Section>
	);
};

export default Methods;
