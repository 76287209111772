import { NavLink as Link, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Section from 'components/Section/section';
import SubtitledImage from 'components/SubtitledImage/subtitled-image';

import Locations from './Locations/locations';
import Upgrades from './Upgrades/upgrades';
import Methods from './Methods/methods';

import Mole from './Mole.jpg';
import Multi from './Multi.jpg';
import Prospector from './Prospector.jpg';
import Roc from './Roc.jpg';

import styles from './mining.module.scss';

const LandingPage = () => (
	<>
		<Helmet>
			<link rel="canonical" href="https://www.scminingmom.com/mining" />
			<title>Star Citizen Mining Mom - Mining</title>
		</Helmet>
		<Section padded>
			<header>
				<h2>Welcome to Mining</h2>
			</header>
			<p>
				Mining is a great way to make aUEC in the Verse, and a whole heck of lot
				of fun. There are currently four different methods of minging in Star
				Citizen:
			</p>
		</Section>
		<Section className={styles.methodGrid}>
			<SubtitledImage href="methods/multi" src={Multi}>
				<h4>Greycat Pyro Multi-Tool</h4>
				<div>
					<p>
						Everything you need to know about Greycat Pyro Multi-Tool mining can
						be found <Link to="methods/multi">here</Link>
					</p>
				</div>
			</SubtitledImage>
			<SubtitledImage href="methods/roc" src={Roc}>
				<h4>Greycat ROC/Greycat ROC-DS</h4>
				<div>
					<p>
						Everything you need to know about Greycat ROC/Greycat ROC-DS mining
						can be found <Link to="methods/roc">here</Link>
					</p>
				</div>
			</SubtitledImage>
			<SubtitledImage href="methods/prospector" src={Prospector}>
				<h4>MISC Prospector</h4>
				<div>
					<p>
						Everything you need to know about MISC Prospector mining can be
						found <Link to="methods/prospector">here</Link>
					</p>
				</div>
			</SubtitledImage>
			<SubtitledImage href="methods/mole" src={Mole}>
				<h4>ARGO Mole</h4>
				<div>
					<p>
						Everything you need to know about ARGO Mole mining can be found{' '}
						<Link to="methods/mole">here</Link>
					</p>
				</div>
			</SubtitledImage>
		</Section>
		<Section padded>
			<header>
				<h2>Let The Following Pages Be Your Guide</h2>
			</header>
			<h3>
				<Link to="methods/multi">Mining Methods</Link>
			</h3>
			<p>
				Mining Methods is where you will be able to see what you can do with
				each mining method available in Star Citizen, such as the{' '}
				<Link to="methods/multi">Greycat Pyro Multi-Tool</Link>,{' '}
				<Link to="methods/roc">Greycat ROC/ROC - DS</Link>,{' '}
				<Link to="methods/prospector">MISC Prospector</Link> and,{' '}
				<Link to="methods/mole">Argo MOLE</Link>, with a calculator to quickly
				and efficiently calculate what your next break will yield in SCU and an
				estimate on aUEC (Before refining costs!)
			</p>
		</Section>
		<Section padded>
			<h3>
				<Link to="upgrades">Ship Upgrades</Link>
			</h3>
			<p>
				Check out Ship Upgrades page to see what mining lasers, gadgets and
				modules are available, all the details pertaining to them and where to
				purchase to help you break those tough rocks!
			</p>
		</Section>
		<Section padded>
			<h3>
				<Link to="locations/gemstones">Gemstone Types &amp; Locations</Link>
			</h3>
			<p>
				If spulunking is your thing start here. Here you will find the resources
				to find Gemstones to mine with your{' '}
				<Link to="methods/multi">Greycat Pyro Multi-Tool</Link>.
			</p>
		</Section>
		<Section padded>
			<h3>
				<Link to="locations/asteroids">Asteroid Types &amp; Locations</Link>
			</h3>
			<p>
				Starting off big huh? For you{' '}
				<Link to="methods/prospector">MISC Prospector</Link> and{' '}
				<Link to="methods/mole">Argo MOLE</Link> owners we have the goods too.
			</p>
		</Section>
		<Section padded>
			<h3>
				<Link to="locations/deposits">Deposits Types &amp; Locations</Link>
			</h3>
			<p>
				If you are rocking a <Link to="methods/roc">Greycat ROC or ROC-DS</Link>{' '}
				then this is where you want to start. Here you will find the resources
				you're looking for, with min/max percentages listed, you'll be making
				profit in no time!
			</p>
		</Section>
	</>
);

const Mining = () => (
	<Routes>
		<Route path="/*" element={<LandingPage />} />
		<Route path="locations" element={<Locations />} />
		<Route path="upgrades" element={<Upgrades />} />
		<Route path="methods/*" element={<Methods />} />
	</Routes>
);

Mining.Navigation = () => (
	<ul>
		<li>
			<Link to="methods">Methods</Link>
		</li>
		<li>
			<Link to="upgrades">Upgrades</Link>
		</li>
		<li>
			<Link to="locations">Locations</Link>
		</li>
	</ul>
);

export default Mining;
