import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import Section from 'components/Section/section';
import styles from './twitch.module.scss';

const loadTwitchEmbed = (callback) => {
	const embedCheck = document.getElementById('twitchEmbedScript');
	if (embedCheck) {
		if (callback && window.Twitch) callback();
	} else {
		const script = document.createElement('script');
		script.src = 'https://embed.twitch.tv/embed/v1.js';
		script.id = 'twitchEmbedScript';
		document.body.appendChild(script);
		script.onload = () => {
			if (callback && window.Twitch) callback();
		};
	}
};

const Twitch = () => {
	const embedRef = useRef();

	useEffect(() => {
		if (embedRef.current && !embedRef.current.firstChild) {
			loadTwitchEmbed(() => {
				new window.Twitch.Embed(embedRef.current, {
					width: '100%',
					height: '100%',
					channel: 'dorathaadestroya',
				});
			});
		}
	}, [embedRef]);

	return (
		<>
			<Section>
				<header>
					<h2>DoraThaaDestroya on Twitch</h2>
				</header>
			</Section>
			<Helmet>
				<link
					rel="canonical"
					href="https://www.scminingmom.com/socials/twitch"
				/>
				<title>Star Citizen Mining Mom - Twitch Stream</title>
			</Helmet>
			<div className={styles.layout} ref={embedRef}></div>
		</>
	);
};

export default Twitch;
