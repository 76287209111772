import { Helmet } from 'react-helmet-async';

import Section from 'components/Section/section';

import Terraphon from './terraphon.jpg';
import Dummaloop from './dumma.jpg';
import styles from './stars.module.scss';

const Stars = () => (
	<>
		<Section className={styles.header}>
			<header>
				<h2>Special shoutouts</h2>
			</header>
		</Section>
		<Section className={styles.layout} padded text>
			<Helmet>
				<link
					rel="canonical"
					href="https://www.scminingmom.com/socials/stars"
				/>
				<title>Star Citizen Mining Mom - Shoutouts</title>
			</Helmet>
			<p>
				Without the help of Terraphon, Andir, and Dumma, this website wouldn’t
				have been possible. I'd like to express my gratitude to each of them
				individually!
			</p>
			<Section className={styles.shoutOut}>
				<header>
					<h3>Andir</h3>
				</header>
				<div>
					<em>
						<i>
							<span>:</span>^]
						</i>
					</em>
				</div>
				<p>
					After hanging around a Star Citizen stream, I met Andir! He became a
					regular in my streams after I started streaming Star Citizen
					exclusively, whether it's really bad jokes or playing with me while I
					stream, he's always finding ways to make me laugh. I approached Andir
					with a dream, he helped me bring this dream to life and be able to
					share my mining knowledge with everyone! Thank you for your endless
					hours of coding, putting up with my crazy high hopes and helping make
					this dream come alive!
				</p>
			</Section>
			<Section className={styles.shoutOut}>
				<header>
					<h3>Terra</h3>
				</header>
				<div>
					<img src={Terraphon} alt="Terraphon" />
				</div>
				<p>
					Terraphon is a close family friend, my main Twitch mod, and someone I
					would consider a mentor when it comes to pushing me towards my goals.
					Terra does all of my sounds and songs for my streams, helps me with
					all of the behind the scenes things and is always pushing me to be a
					better version of myself! One morning we were chatting and he had this
					idea of making a website for all things Star Citizen mining, I thought
					he was joking.. Thank you Terra, for pushing me to do this and helping
					me figure out how to compile all the information in such a way,
					everyone can use it!
				</p>
				<footer>
					<a
						alt="Terraphon"
						href="https://terraphonmusic.com/"
						rel="noreferrer"
						target="_blank"
					>
						terraphonmusic.com
					</a>
				</footer>
			</Section>
			<Section className={styles.shoutOut}>
				<header>
					<h3>Dumma</h3>
				</header>
				<div>
					<img src={Dummaloop} alt="Dummaloop" />
				</div>
				<p>
					I met Dummaloop 2 years ago, when a partner streamer he mods for,
					raided me! Since that day, you can regularly find him in my streams,
					just hanging out, gambling his quartz away or trying to make me laugh
					for everyone in stream to earn some quartz. For a long time, I was
					actually afraid to talk to Dumma, don’t ask why, it doesn’t make
					sense.. But here we are, he’s been spending a ton of time working on
					the website alongside Andir! Thank you Dumma for putting in all of
					this work and showing me my dreams aren’t as crazy as I thought!
				</p>
			</Section>
		</Section>
	</>
);

export default Stars;
