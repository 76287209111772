import { Helmet } from 'react-helmet-async';
import AsteroidTypes from 'components/AsteroidSignatures/asteroid-signatures';
import AsteroidLocations from 'components/AsteroidLocations/asteroid-locations';
import HaloBandDistances from 'components/HaloBandDistances/halo-band-distances';
import Section from 'components/Section/section';

import styles from './asteroids.module.scss';

const Asteroids = () => {
	return (
		<Section padded className={styles.layout}>
			<Helmet>
				<link
					rel="canonical"
					href="https://www.scminingmom.com/mining/locations/asteroids"
				/>
				<title>Star Citizen Mining Mom - Asteroid Types & Locations</title>
			</Helmet>
			<Section className={styles.header}>
				<header>
					<h2>Asteroid Types</h2>
				</header>
			</Section>
			<Section className={styles.types}>
				<AsteroidTypes />
			</Section>
			<Section className={`${styles.locations} panel`}>
				<AsteroidLocations />
			</Section>
			<Section className={`${styles.distances} panel`}>
				<HaloBandDistances />
			</Section>
		</Section>
	);
};

export default Asteroids;
