const Conditional = ({ children, component, condition, ...rest }) => {
	const OutputComponent = component;

	return condition ? (
		<OutputComponent {...rest}>{children}</OutputComponent>
	) : (
		children
	);
};

export default Conditional;
