import { NavLink as Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import HorizontalCards from 'components/HorizontalCards/cards';
import Key from 'components/Key/key';

import Section from 'components/Section/section';

import HT10 from './HT10.jpg';
import HT13 from './HT13.jpg';
import HT14 from './HT14.jpg';
import HT16 from './HT16.jpg';
import HT17 from './HT17.jpg';
import HT19 from './HT19.jpg';
import HT20 from './HT20.jpg';

import styles from '../subpage.module.scss';

const UEC = new Intl.NumberFormat(undefined, {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
	style: 'decimal',
});

const Roc = () => (
	<div className={styles.layout}>
		<Helmet>
			<link
				rel="canonical"
				href="https://www.scminingmom.com/mining/methods/roc"
			/>
			<title>Star Citizen Mining Mom - Roc Mining</title>
		</Helmet>
		<Section padded aside>
			<header>
				<h2>Greycat Remote Ore Collector (ROC / ROC-DS)</h2>
			</header>
			<p>
				The Remote Ore Collector, aka ROC, is a Greycat Industrial manufactured
				ground mining vehicle. Its compact size allows it to be loaded into many
				medium size ships with a ramp. The vehicle is fitted with a fully
				rotatable size 0 Mining Laser. When ROC mining, you’ll be breaking small
				deposits that give gems and collecting them similar to how you would in
				a Prospector or MOLE, but on the ground. These gems don’t require a
				refinery and can be sold straight out of your ROC with multiple options
				for places to sell!
			</p>
		</Section>
		<Section padded>
			<header>
				<h3>Roc / Roc-DS How to</h3>
			</header>
		</Section>
		<HorizontalCards numbered>
			<HorizontalCards.Card>
				Load your ROC into a medium/large cargo ship from an outpost with
				platinum bay landing pads.
			</HorizontalCards.Card>
			<HorizontalCards.Card>Plot your destination</HorizontalCards.Card>
			<HorizontalCards.Card>
				Once out of QT, press <Key>V</Key> to bring out your scanner
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Use your mouse wheel to adjust your scanning FOV to 180 degrees
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hit <Key>Tab</Key> to ping
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				When you're 12 km away from a cube, use your mouse wheel set your FOV to
				2 degrees
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hit <Key>Tab</Key> again and a small white diamond (Or multiples) will
				show up
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hold your left mouse button down to begin scanning, details will appear
				on the right side of your HUD.
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				If the diamond(s) are gemstones (Small blue diamonds will appear if its
				a ROC minable), head towards the diamond(s)
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HT10}>
				If its a ROC minable, land and get into your ROC
			</HorizontalCards.Card>
			<HorizontalCards.Card>Get close to the minable</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hit <Key>M</Key> to bring out your mining laser.
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HT13}>
				Press your left mouse button to start your laser
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HT14}>
				Use your mouse wheel to adjust your laser power to get the charge into
				the optimal zone (green)
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hold in the optimal until the deposit breaks
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HT16}>
				Hit your right mouse button to bring out your extractor
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HT17}>
				Hit your left mouse button to begin collecting the gems off the ground
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Store your ROC back in your ship
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HT19}>
				Head to an Admin Office or Mining facility of your choice.
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HT20}>
				Use the terminal to sell directly from you ROC inventory
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Repeat steps 1 through 21 and make profit!
			</HorizontalCards.Card>
		</HorizontalCards>
		<Section padded>
			<p>
				Now that you've earned roughly {UEC.format(135252)} aUEC to rent a MISC
				Prospector for 3 days.{' '}
				<Link to="/mining/methods/prospector">Let’s go</Link>!
			</p>
		</Section>
		<Section padded>
			<header>
				<h3>Gear / Items</h3>
			</header>
			<p>Greycat Industrial ROC</p>
			<p>Medium/Large ship that can fit a ground vehicle</p>
			<p>An environment suit depending on the planets or moons temperature</p>
		</Section>
		<Section padded>
			<header>
				<h3>Where to Go</h3>
			</header>
			<p>
				With the Greycat Industrial ROC, you’ll want to look at planets or
				moons. Using the table in the Gems section, you’ll be able to find the
				perfect places for the gems you’re looking for! An example of a perfect
				spot is, Aberdeen for Hadenite (Pinkish, purple mineral deposit)
			</p>
		</Section>
		<Section padded>
			<header>
				<h3>Where to Buy a ROC</h3>
			</header>
			<p>
				<a href="https://robertsspaceindustries.com/pledge/ships/roc/ROC#buying-options">
					$55.00 USD - Pledge Store
				</a>
			</p>
			<p>{UEC.format(103950)} aUEC - Lorville - New Deal</p>
		</Section>
		<Section padded>
			<header>
				<h3>Where to Rent</h3>
			</header>
			<p>Refinery Stations at the rental terminal on the Refinery Deck</p>
		</Section>
	</div>
);

export default Roc;
