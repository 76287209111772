import styles from './about.module.scss';
import { Helmet } from 'react-helmet-async';
import Section from 'components/Section/section';
import Dora320 from './Dora-320_x_604.jpg';

const About = () => {
	return (
		<>
			<Section className={styles.header}>
				<header>
					<h2>About Dora</h2>
				</header>
			</Section>
			<Section padded className={styles.layout}>
				<Helmet>
					<link rel="canonical" href="https://www.scminingmom.com/about" />
					<title>Star Citizen Mining Mom - About</title>
				</Helmet>

				<div className={styles.textContainer}>
					<p>
						I'm Dorathaadestroya, AKA Dora! On December 17, 2021 I jumped into
						my first day in the verse! I was so excited, and I explored around
						New Babbage for a few hours before I found my way to the hangar and
						was able to take my Aegis Avenger Titan out for the very first time!
					</p>
					<p>
						I had no idea where I was going or what I was doing. After clicking
						around on things for a few minutes, I plotted a Quantum jump, and I
						was headed for Port Olisar! After several minutes of quantum
						travelling, I dropped out of Quantum and was immediately killed by
						some ne'er-do-well.
					</p>
					<p>
						It was at that moment I knew that I wanted nothing to do with other
						citizens!
					</p>
					<p>
						The next day a close family friend, my Twitch mod Terraphon, and I
						met up and he took me to buy my first MISC Prospector and MISC
						Freelancer Max, as a means to start making some aUEC. After that, he
						showed me the mining basics and showed me where to go out and look
						for asteroids. I was hooked!
					</p>
					<p>
						I tried doing other missions, such as, delivery, bounties, even
						bunkers, but I would keep going back to mining, I knew once I wasn't
						happy doing those other things that mining would have my heart, and
						in Star Citizen, it does!
					</p>
					<p>
						I'm a Star Citizen, I'm a miner, and I'm a mom of two. Welcome to
						SCMiningMom.com!
					</p>
					<p>
						Business email:{' '}
						<a href="mailto:Dorathaadestroya@gmail.com">
							Dorathaadestroya@gmail.com
						</a>
					</p>
				</div>
				<div className={styles.imageContainer}>
					<img alt="DoraThaaDestroya" className={styles.image} src={Dora320} />
				</div>
			</Section>
		</>
	);
};

export default About;
