import { NavLink as Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import HorizontalCards from 'components/HorizontalCards/cards';
import Key from 'components/Key/key';

import Section from 'components/Section/section';

import HTAH01 from './HTAH01.jpg';
import HTAH03 from './HTAH03.jpg';
import HTAH04 from './HTAH04.jpg';
import HTAH12 from './HTAH12.jpg';
import HTAH17 from './HTAH17.jpg';

import HTPS01 from './HTPS01.jpg';
import HTPS04 from './HTPS04.jpg';
import HTPS06 from './HTPS06.jpg';
import HTPS08 from './HTPS08.jpg';
import HTPS10 from './HTPS10.jpg';
import HTPS14 from './HTPS14.jpg';
import HTPS16 from './HTPS16.jpg';

import styles from '../subpage.module.scss';

const UEC = new Intl.NumberFormat(undefined, {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
	style: 'decimal',
});

const Ship = () => (
	<div className={styles.layout}>
		<Helmet>
			<link
				rel="canonical"
				href="https://www.scminingmom.com/mining/methods/mole"
			/>
			<title>Star Citizen Mining Mom - Mole Mining</title>
		</Helmet>
		<Section padded>
			<header>
				<h2>Argo Mole</h2>
			</header>
			<p>
				ARGO's pragmatic no-nonsense approach to ship design is celebrated in
				the unmistakable silhouette of the MOLE. Just one look at this machine
				and you know it means business. Tap into your full potential with the
				combined force of ARGO's patented trilateral mining system. The MOLE has
				a total of 24 mineral pods, each holding 12 SCU. Eight pods are usable
				at one time - when they're full, either head home or jettison them for
				collection by another vessel and carry on mining. Three independently
				controlled articulated extraction stations allow for maximum power and
				near-limitless versatility. The MOLE lives by the adage 'many hands make
				for light work'.
			</p>
		</Section>
		<Section padded>
			<header>
				<h3>Aaron Halo Mining How to</h3>
			</header>
		</Section>
		<HorizontalCards numbered>
			<HorizontalCards.Card img={HTAH01}>
				Plot your destination
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Once out of QT, press <Key>V</Key> to bring out your scanner
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH03}>
				Use your mouse wheel to adjust your scanning FOV to 180 degrees
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH04}>
				Hit <Key>Tab</Key> to ping
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				When you're 12 km away from a cube, use your mouse wheel to set your FOV
				to 2 degrees
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hit <Key>Tab</Key> to ping and a small white diamond (Or multiples) will
				show up
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Head towards the asteroid, when closer, hold your left mouse button to
				scan the asteroid again, more details about the asteroid will pop up
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				When you are within 5 m of the rock, head back to your mining seat,
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Once in the mining seat, press your left mouse button to start your
				laser
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH12}>
				Use your mouse wheel to adjust your laser power to get the charge into
				the (green) optimal zone. When doing this, you'll want to hit the sweet
				spot, you'll know you hit it when you see lots of sparks, the more
				sparks, the better!
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hold in the optimal until the asteroid breaks
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Continue steps 11 through 13 adjusting your laser power accordingly
				until all the asteroid pieces break
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hit your right mouse button to bring out your extractor
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Point your extractor at each broken piece to see what you will be
				collecting
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH17}>
				Once everything has been scanned, hit your left mouse button to start
				extracting
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Once everything is collected, head back to your pilot's seat
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Head back to the refinery of your choice
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Once at the refinery, select your preferred refinery method
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Repeat steps 1 through 30, make profit!
			</HorizontalCards.Card>
		</HorizontalCards>
		<Section padded>
			<header>
				<h3>Planet Side Mining How to</h3>
			</header>
		</Section>

		<HorizontalCards numbered>
			<HorizontalCards.Card img={HTPS01}>
				Plot your destination
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Once out of QT, press <Key>V</Key> to bring out your scanner
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Use your mouse wheel to adjust your scanning FOV to 180 degrees
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS04}>
				Hit <Key>Tab</Key> to ping
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				When you're 12 km away from a cube, use your mouse wheel to set your FOV
				to 2 degrees
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS06}>
				Hit <Key>Tab</Key> again and a small white diamond (Or multiples) will
				show up
			</HorizontalCards.Card>
			<HorizontalCards.Card>Head towards the diamond</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS08}>
				Once you get closer, hold the left mouse button and your scanner will
				register what is in the deposit
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				When you are within 5 m of the rock, get out of your pilot's seat, head
				to one of the 3 mining seats,
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS10}>
				Once in the mining seat, turn on your mining laser and press your left
				mouse button to start the laser
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Use your mouse wheel to adjust your laser power to get the charge into
				the (green) optimal zone. When doing this, you'll want to hit the sweet
				spot, you'll know you hit it when you see lots of sparks, the more
				sparks, the better!
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hold in the optimal until the deposit breaks
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Continue steps 10 through 12 adjusting your laser power accordingly
				until all the deposit pieces break
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS14}>
				Hit your right mouse button to bring out your extractor
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Point your extractor at each broken piece to see what you will be
				collecting
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS16}>
				Once everything has been scanned, hit your left mouse button to start
				extracting
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Once everything is collected, head back to your pilot's seat
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Head back to the refinery of your choice
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Once at the refinery, select your preferred refinery method
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Repeat steps 1 through 19, make profit!
			</HorizontalCards.Card>
		</HorizontalCards>
		<Section padded>
			<header>
				<h3>Where to Go</h3>
			</header>
			<p>
				There is surface mining, which is done on a planet or a moon. Or there
				is asteroid mining, which is done in the Aaron Halo. Both will provide
				you with tons of options for mining materials. When surface mining,
				you'll come across what are called deposits. When mining in the Aaron
				Halo, you'll come across what are called asteroids.
			</p>
			<p>
				<Link to="/mining/locations/asteroids">See our locations page!</Link>
			</p>
		</Section>
		<Section padded>
			<header>
				<h3>Where to Buy</h3>
			</header>
			<p>
				<a href="https://robertsspaceindustries.com/pledge/ships/argo-mole/MOLE#buying-options">
					$315.00 USD - Pledge Store
				</a>
			</p>
			<p>{UEC.format(8930250)} aUEC - Lorville - New Deal</p>
		</Section>
	</div>
);

export default Ship;
