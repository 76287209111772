import { Children, useState } from 'react';

import Card from './Card/card';

import styles from './cards.module.scss';

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const determineClassName = (current, index) =>
	({
		'-2': styles.hidePrev,
		'-1': styles.prev,
		0: styles.current,
		1: styles.next,
		2: styles.hideNext,
	})[clamp(index - current, -2, 2)];

const HorizontalCards = ({ children }) => {
	const [current, setCurrent] = useState(0);
	const content = Children.map(children, (child, index) => {
		const className = determineClassName(current, index);
		const action = () => {
			switch (className) {
				case styles.current: {
					if (Children.count(children) - 1 > index) {
						setCurrent(current + 1);
					}
					break;
				}
				case styles.next: {
					setCurrent(current + 1);
					break;
				}
				case styles.prev: {
					setCurrent(current - 1);
					break;
				}
				default:
			}
		};
		return (
			<li
				className={`${styles.listItem} ${className}`}
				onClick={action}
				data-slide={index + 1}
			>
				{child}
			</li>
		);
	});
	return (
		<section className={styles.layout}>
			<ul className={styles.list}>{content}</ul>
			<footer className={styles.slideNavContainer}>
				{Children.map(children, (child, index) => (
					<button
						className={
							index === current ? styles.slideNavActive : styles.slideNav
						}
						key={`slideNav_${index}`}
						onClick={() => setCurrent(index)}
					>
						{index + 1}
					</button>
				))}
			</footer>
		</section>
	);
};

HorizontalCards.Card = Card;

export default HorizontalCards;
