import { Children, useEffect, useRef, useState } from 'react';
import useToggle from 'hooks/useToggle';

import styles from './combo-picker.module.scss';

const ComboPicker = ({ children, className, currentIndex = 0, onSelect }) => {
	const comboRef = useRef();
	const [current, setCurrent] = useState(currentIndex);
	const [comboOpen, toggleCombo] = useToggle();

	useEffect(() => {
		const closeCombo = (event) => {
			const parts = [
				comboRef.current,
				...comboRef.current.querySelectorAll('*'),
			];
			!parts.includes(event.target) && comboOpen && toggleCombo();
		};
		window.addEventListener('click', closeCombo);

		return () => window.removeEventListener('click', closeCombo);
	}, [comboOpen, toggleCombo]);

	return (
		<div
			className={`${styles.combo}${className ? ` ${className}` : ''}`}
			onClick={toggleCombo}
			ref={comboRef}
		>
			<label>{Children.toArray(children)[current]}</label>
			<button type="button">▶</button>
			{comboOpen ? (
				<ul className={styles.comboList}>
					{Children.map(children, (child, index) => (
						<li
							onClick={() => {
								setCurrent(index);
								onSelect(index);
							}}
						>
							{child}
						</li>
					))}
				</ul>
			) : null}
		</div>
	);
};

export default ComboPicker;
