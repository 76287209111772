import { useState } from 'react';

import bands from 'data/halo_bands.json';
import locations from 'data/locations.json';

import styles from './halo-band-distances.module.scss';

import refineryIcon from './refinery.png';

const origins = Object.values(bands).reduce(
	(accumulator, bandData) => ({
		...accumulator,
		...Object.entries(bandData).reduce(
			(originAccumulator, [originKey, originData]) => ({
				...originAccumulator,
				[originKey]: {
					...Object.keys(originData).reduce(
						(destinationAccumulator, destinationKey) => ({
							...destinationAccumulator,
							[destinationKey]: Object.keys(bands).reduce(
								(bandAccumulator, bandKey) => ({
									...bandAccumulator,
									[bandKey]: bands[bandKey][originKey][destinationKey],
								}),
								{},
							),
						}),
						{},
					),
				},
			}),
			{},
		),
	}),
	{},
);

const numberFormat = new Intl.NumberFormat();

const HaloBandDistances = () => {
	const [origin, setOrigin] = useState();
	const [destination, setDestination] = useState();
	return (
		<section className={styles.layout}>
			<header>
				<h3>Halo Band Distances</h3>
			</header>
			<p>
				Select a Start Location then a Target Location and we will show you the
				Distance to Target of each of the Halo bands. You will need to manually
				cancel Quantum Travel at the desired Distance to Target.
			</p>
			<p className={styles.refineryNote}>
				<img alt="refinery" src={refineryIcon} title="Refinery" /> denotes a
				location with a refinery
			</p>
			<div className={styles.bandCalculator}>
				<div className={styles.originGroup}>
					<header>
						<h4>Start Location</h4>
					</header>
					<div>
						{Object.keys(origins).map((originKey) => (
							<div
								className={`${styles.origin} ${
									origin === originKey ? styles.active : ''
								}`}
								key={originKey}
								onClick={() => {
									setOrigin(originKey);
									setDestination(null);
								}}
							>
								{locations[originKey].name}
								{locations[originKey]?.properties?.refinery ? (
									<img alt="refinery" src={refineryIcon} title="Refinery" />
								) : null}
							</div>
						))}
					</div>
				</div>
				<div className={styles.destinationGroup}>
					<header>
						<h4>Target Location</h4>
					</header>
					<div>
						{origins?.[origin]
							? Object.keys(origins[origin]).map((destinationKey) => (
									<div
										className={`${styles.destination} ${
											destination === destinationKey ? styles.active : ''
										}`}
										key={`${origin}_${destinationKey}`}
										onClick={() => setDestination(destinationKey)}
									>
										{locations[destinationKey].name}
										{locations[destinationKey]?.properties?.refinery ? (
											<img alt="refinery" src={refineryIcon} title="Refinery" />
										) : null}
									</div>
							  ))
							: null}
					</div>
				</div>
				<div className={styles.bandGroup}>
					<header>
						<h4>Distance to Target</h4>
					</header>
					<div>
						{origins?.[origin]?.[destination]
							? Object.entries(origins[origin][destination]).map(
									([bandKey, bandRange]) => (
										<div
											className={styles.band}
											key={`${origin}_${destination}_${bandKey}`}
										>
											<span>{locations[bandKey].name}: </span>
											<span>{numberFormat.format(bandRange)} km</span>
										</div>
									),
							  )
							: null}
					</div>
				</div>
			</div>
		</section>
	);
};

export default HaloBandDistances;
