import { useEffect, useState } from 'react';
import { matches, omit } from 'lodash';
import { Helmet } from 'react-helmet-async';

import ComboPicker from 'components/ComboPicker/combo-picker';
import Section from 'components/Section/section';

import data from 'data/mining_items.json';

import styles from './upgrades.module.scss';

const removeUndefinedKeyValues = (obj) =>
	Object.entries(obj).reduce(
		(accumulator, [key, value]) => ({
			...accumulator,
			...(typeof value === 'undefined' ? {} : { [key]: value }),
		}),
		{},
	);

const ExpandableContent = ({ children, isOpen = false, title }) => {
	const [open, setOpen] = useState(isOpen);
	return (
		<section>
			<button
				className={styles.filterLink}
				onClick={() => {
					setOpen(!open);
				}}
			>
				{open ? 'Hide' : 'Show'} {title}
			</button>
			<div>{open ? children : null}</div>
		</section>
	);
};

const Upgrade = ({ isOpen, item }) => {
	const pricing = Object.entries(
		item?.availability?.reduce(
			(accumulator, available) => ({
				...accumulator,
				[available.price]: [
					...(accumulator?.[available.price] || []),
					available.location,
				],
			}),
			{},
		),
	).sort((a, b) => a[0] - b[0]);
	const numberFormat = new Intl.NumberFormat();

	return (
		<li
			className={
				item?.recommended ? styles.gridCellRecommended : styles.gridCell
			}
			{...(item.recommended ? { title: 'Recommended!' } : {})}
		>
			<h3>
				{item.name}
				{item?.subType ? ` ${item.subType}` : null}
			</h3>
			<p>{item.description}</p>
			<ul>
				{Object.entries(item.properties).map(([key]) => (
					<li key={`${item.id}_${key}`}>
						{key}: <span>{item.properties[key]}</span>
					</li>
				))}
			</ul>
			<ExpandableContent title="Availability" isOpen={isOpen}>
				{pricing.length ? (
					<table>
						<thead>
							<tr>
								<th>Location{pricing.length > 1 ? 's' : ''}</th>
								<th>Price (aUEC)</th>
							</tr>
						</thead>
						<tbody>
							{pricing.map(([price, locations]) =>
								locations.map((location, index, allLocations) => (
									<tr key={`key_${price}_${location}`}>
										<td>{location}</td>
										{index === 0 ? (
											<td rowSpan={allLocations.length}>
												{numberFormat.format(price)}
											</td>
										) : null}
									</tr>
								)),
							)}
						</tbody>
					</table>
				) : (
					<div>Not available for purchase</div>
				)}
			</ExpandableContent>
		</li>
	);
};

const Upgrades = () => {
	const url = new URL(window.location);

	const [historyState, setHistoryState] = useState(
		Object.fromEntries(url.searchParams),
	);

	useEffect(() => {
		const updatedUrl = new URL(window.location);
		updatedUrl.search = new URLSearchParams(historyState);
		window.history.pushState(historyState, '', updatedUrl);
	}, [historyState]);

	const filteredMiningLasers = Object.entries(data)
		.map(([key, item]) => ({ id: key, ...item }))
		.filter(
			matches({
				type: 'Mining Laser',
				...(Object.keys(historyState).length
					? { properties: historyState }
					: {}),
			}),
		);

	const filteredMiningLaserModules = Object.entries(data)
		.map(([key, item]) => ({ id: key, ...item }))
		.filter(
			matches({
				type: 'Mining Module',
				...(Object.keys(historyState).length
					? { properties: historyState }
					: {}),
			}),
		);

	const filteredMiningGadgets = Object.entries(data)
		.map(([key, item]) => ({ id: key, ...item }))
		.filter(
			matches({
				type: 'Mining Gadget',
				...(Object.keys(historyState).length
					? { properties: historyState }
					: {}),
			}),
		);

	const filteredMiningBags = Object.entries(data)
		.map(([key, item]) => ({ id: key, ...item }))
		.filter(
			matches({
				type: 'Mining Bag',
				...(Object.keys(historyState).length
					? { properties: historyState }
					: {}),
			}),
		);

	const propertyValues = filteredMiningLasers.reduce(
		(accumulator, { properties }) => ({
			...accumulator,
			...Object.entries(properties).reduce(
				(additional, [key, value]) => ({
					...additional,
					[key]: Array.from(
						new Set(
							[...(accumulator?.[key] || []), value].sort(
								(a, b) => parseFloat(a) - parseFloat(b),
							),
						),
					),
				}),
				{},
			),
		}),
		{},
	);

	const [open, setFilterOpen] = useState(false);

	return (
		<section className={styles.layout}>
			<Helmet>
				<link
					rel="canonical"
					href="https://www.scminingmom.com/mining/upgrades"
				/>
				<title>Star Citizen Mining Mom - Mining Upgrades</title>
			</Helmet>
			<aside>
				<section className={`${styles.loadouts}`}>
					<header>
						<h2>Loadouts</h2>
					</header>
					<div>
						<a
							rel="noreferrer"
							target="_blank"
							href="https://youtu.be/dT5Hh2Bxq6A?si=IGA14PYyKXJZNVI0"
						>
							<em>MY</em> recommended Prospector loadout
						</a>
					</div>
				</section>
				<header>
					<h2>Filters</h2>
				</header>
				<button
					className={styles.filterLink}
					onClick={() => {
						setFilterOpen(!open);
					}}
				>
					{open ? 'Hide Filters' : 'Show Filters'}
				</button>
				<div
					className={`${styles.filters} ${open ? styles.show : styles.hide}`}
				>
					{Object.entries(historyState).map(([key, value]) => (
						<div className={styles.activeFilter} key={key}>
							<label>
								{key}: {value}
							</label>
							<button
								onClick={() => {
									setHistoryState(omit(historyState, key));
								}}
								type="button"
							>
								X
							</button>
						</div>
					))}
					{Object.entries(propertyValues)
						.filter(([property, values]) => values.length > 1)
						.map(([property, values]) => (
							<section className={styles.contentPicker} key={property}>
								<label>{property}</label>
								<ComboPicker
									onSelect={(index) => {
										setHistoryState(
											removeUndefinedKeyValues({
												...historyState,
												[property]: values[index - 1],
											}),
										);
									}}
									currentIndex={values.indexOf(historyState[property]) + 1}
								>
									{[undefined, ...values].map((value) => (
										<span key={`${property}_${value}`}>{value}</span>
									))}
								</ComboPicker>
							</section>
						))}
				</div>
			</aside>
			{filteredMiningLasers.length ? (
				<Section fill className={styles.listSection}>
					<header>
						<h2>Mining Lasers</h2>
					</header>
					<ul className={styles.grid}>
						{filteredMiningLasers.map((upgrade) => (
							<Upgrade
								key={upgrade.id}
								isOpen={filteredMiningLasers?.length < 5}
								item={upgrade}
							/>
						))}
					</ul>
				</Section>
			) : null}
			{filteredMiningLaserModules.length ? (
				<Section fill className={styles.listSection}>
					<header>
						<h2>Mining Laser Modules</h2>
					</header>
					<ul className={styles.grid}>
						{filteredMiningLaserModules.map((upgrade) => (
							<Upgrade
								key={upgrade.id}
								isOpen={filteredMiningLaserModules?.length < 5}
								item={upgrade}
							/>
						))}
					</ul>
				</Section>
			) : null}{' '}
			{filteredMiningGadgets.length ? (
				<Section fill className={styles.listSection}>
					<header>
						<h2>Mining Gadgets</h2>
					</header>
					<ul className={styles.grid}>
						{filteredMiningGadgets.map((upgrade) => (
							<Upgrade
								key={upgrade.id}
								isOpen={filteredMiningGadgets?.length < 5}
								item={upgrade}
							/>
						))}
					</ul>
				</Section>
			) : null}
			{filteredMiningBags.length ? (
				<Section fill className={styles.listSection}>
					<header>
						<h2>Mining Bags</h2>
					</header>
					<ul className={styles.grid}>
						{filteredMiningBags.map((upgrade) => (
							<Upgrade
								key={upgrade.id}
								isOpen={filteredMiningBags?.length < 5}
								item={upgrade}
							/>
						))}
					</ul>
				</Section>
			) : null}
		</section>
	);
};

export default Upgrades;
