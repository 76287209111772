import { Helmet } from 'react-helmet-async';
import DepositSignatures from 'components/DepositSignatures/deposit-signatures';
import DepositLocations from 'components/DepositLocations/deposit-locations';
import Section from 'components/Section/section';

import styles from './deposits.module.scss';

const Deposits = () => {
	return (
		<Section className={`${styles.layout} padded`}>
			<Helmet>
				<link
					rel="canonical"
					href="https://www.scminingmom.com/mining/locations/deposits"
				/>
				<title>Star Citizen Mining Mom - Deposits Types & Locations</title>
			</Helmet>
			<Section className={styles.header}>
				<header>
					<h2>Deposit Types</h2>
				</header>
			</Section>
			<Section className={styles.types}>
				<DepositSignatures />
			</Section>
			<Section className={`${styles.locations} panel`}>
				<DepositLocations />
			</Section>
		</Section>
	);
};

export default Deposits;
