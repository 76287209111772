import { Helmet } from 'react-helmet-async';

import Section from 'components/Section/section';

import styles from './refining.module.scss';

const Refining = () => (
	<Section className={styles.layout}>
		<Helmet>
			<link
				rel="canonical"
				href="https://www.scminingmom.com/post-mining/refining"
			/>
			<title>Star Citizen Mining Mom - Refining</title>
		</Helmet>
		<Section className={styles.methods} panel>
			<header>
				<h3>Methods</h3>
			</header>
			<div className="overflow">
				<table>
					<thead>
						<tr>
							<th>
								<div>Method</div>
							</th>
							<th>
								<div>Yield</div>
							</th>
							<th>
								<div>Cost</div>
							</th>
							<th>
								<div>Time</div>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Cormack Method</td>
							<td className={styles.bad}>Low</td>
							<td>Medium</td>
							<td className={styles.good}>Short</td>
						</tr>
						<tr>
							<td>Dinyx Solventation</td>
							<td className={styles.good}>High</td>
							<td className={styles.good}>Low</td>
							<td className={styles.bad}>Long</td>
						</tr>
						<tr>
							<td>Electrostarolysis</td>
							<td>Medium</td>
							<td>Medium</td>
							<td className={styles.good}>Short</td>
						</tr>
						<tr>
							<td>Ferron Exchange</td>
							<td className={styles.good}>High</td>
							<td>Medium</td>
							<td>Medium</td>
						</tr>
						<tr>
							<td>Gaskin Process</td>
							<td>Medium</td>
							<td className={styles.bad}>High</td>
							<td className={styles.good}>Short</td>
						</tr>
						<tr>
							<td>Kazen Winnowing</td>
							<td className={styles.bad}>Low</td>
							<td className={styles.good}>Low</td>
							<td className={styles.good}>Short</td>
						</tr>
						<tr>
							<td>Pyrometric Cromalysis</td>
							<td className={styles.good}>High</td>
							<td className={styles.bad}>High</td>
							<td>Medium</td>
						</tr>
						<tr>
							<td>Thermonatic Deposition</td>
							<td>Medium</td>
							<td className={styles.good}>Low</td>
							<td>Medium</td>
						</tr>
						<tr>
							<td>XCR Reaction</td>
							<td className={styles.bad}>Low</td>
							<td className={styles.bad}>High</td>
							<td className={styles.good}>Short</td>
						</tr>
					</tbody>
				</table>
			</div>
			<footer>
				<ul>
					<li className="bad">Unfavorable</li>
					<li className="good">Favorable</li>
				</ul>
			</footer>
		</Section>
		<Section className={styles.locations} panel>
			<header>
				<h3>Location Bonuses</h3>
			</header>
			<div className="overflow">
				<table>
					<thead>
						<tr>
							<th>Materials</th>
							<th>
								<div title="Green Glade Station">HUR L1</div>
							</th>
							<th>
								<div title="Faithful Dream Station">HUR L2</div>
							</th>
							<th>
								<div title="Ambitious Dream Station">CRU L1</div>
							</th>
							<th>
								<div title="Wide Forest Station">ARC L1</div>
							</th>
							<th>
								<div title="Lively Pathway Station">ARC L2</div>
							</th>
							<th>
								<div title="Faint Glen Station">ARC L4</div>
							</th>
							<th>
								<div title="Shallow Frontier Station">MIC L1</div>
							</th>
							<th>
								<div title="Long Forest Station">MIC L2</div>
							</th>
							<th>
								<div title="Modern Icarus Station">MIC L5</div>
							</th>
							<th>
								<div title="Magnus Jump Station">Magnus JS</div>
							</th>
							<th>
								<div title="Pyro Jump Station">Pyro JS</div>
							</th>
							<th>
								<div title="Terra Jump Station">Terra JS</div>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Agricium</td>
							<td className={styles.bad}>-8%</td>
							<td className={styles.bad}>-2%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-4%</td>
							<td className={styles.good}>8%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>8%</td>
						</tr>
						<tr>
							<td>Aluminum</td>
							<td className={styles.bad}>-4%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-5%</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-3%</td>
							<td className={styles.good}>7%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-5%</td>
							<td>&ndash;</td>
							<td className={styles.good}>7%</td>
						</tr>
						<tr>
							<td>Beryl</td>
							<td>&ndash;</td>
							<td className={styles.good}>1%</td>
							<td className={styles.good}>7%</td>
							<td className={styles.good}>7%</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-4%</td>
							<td className={styles.bad}>-6%</td>
							<td className={styles.bad}>-8%</td>
							<td className={styles.good}>7%</td>
							<td className={styles.good}>7%</td>
							<td className={styles.good}>7%</td>
							<td className={styles.bad}>-6%</td>
						</tr>
						<tr>
							<td>Bexalite</td>
							<td className={styles.bad}>-2%</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-6%</td>
							<td>&ndash;</td>
							<td className={styles.good}>2%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>9%</td>
							<td className={styles.good}>12%</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-6%</td>
							<td>&ndash;</td>
						</tr>
						<tr>
							<td>Borase</td>
							<td className={styles.good}>1%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>2%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-3%</td>
							<td className={styles.good}>9%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
						</tr>
						<tr>
							<td>Copper</td>
							<td className={styles.bad}>-5%</td>
							<td className={styles.bad}>-3%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>6%</td>
							<td className={styles.bad}>-4%</td>
							<td className={styles.good}>4%</td>
							<td className={styles.good}>2%</td>
							<td className={styles.good}>9%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>4%</td>
						</tr>
						<tr>
							<td>Corundum</td>
							<td className={styles.bad}>-5%</td>
							<td>&ndash;</td>
							<td className={styles.good}>7%</td>
							<td className={styles.bad}>-4%</td>
							<td className={styles.bad}>-3%</td>
							<td className={styles.bad}>-9%</td>
							<td className={styles.good}>2%</td>
							<td className={styles.good}>6%</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-4%</td>
							<td className={styles.good}>7%</td>
							<td className={styles.good}>2%</td>
						</tr>
						<tr>
							<td>Gold</td>
							<td className={styles.bad}>-3%</td>
							<td className={styles.good}>1%</td>
							<td className={styles.bad}>-6%</td>
							<td>&ndash;</td>
							<td className={styles.good}>7%</td>
							<td className={styles.bad}>-4%</td>
							<td className={styles.good}>1%</td>
							<td className={styles.good}>9%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-6%</td>
							<td className={styles.good}>1%</td>
						</tr>
						<tr>
							<td>Hephaestanite</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-2%</td>
							<td className={styles.bad}>-4%</td>
							<td className={styles.bad}>-8%</td>
							<td className={styles.bad}>-5%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>8%</td>
							<td className={styles.bad}>-4%</td>
							<td className={styles.bad}>-2%</td>
							<td>&ndash;</td>
						</tr>
						<tr>
							<td>Iron</td>
							<td className={styles.bad}>-5%</td>
							<td>&ndash;</td>
							<td className={styles.good}>2%</td>
							<td className={styles.good}>1%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>8%</td>
							<td className={styles.good}>1%</td>
							<td className={styles.good}>2%</td>
							<td>&ndash;</td>
						</tr>
						<tr>
							<td>Laranite</td>
							<td className={styles.good}>2%</td>
							<td className={styles.bad}>-1%</td>
							<td className={styles.bad}>-8%</td>
							<td className={styles.bad}>-2%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>2%</td>
							<td className={styles.bad}>-1%</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-2%</td>
							<td className={styles.bad}>-8%</td>
							<td className={styles.good}>2%</td>
						</tr>
						<tr>
							<td>Quantanium</td>
							<td className={styles.good}>2%</td>
							<td className={styles.good}>2%</td>
							<td>&ndash;</td>
							<td className={styles.good}>3%</td>
							<td className={styles.good}>3%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>1%</td>
							<td>&ndash;</td>
							<td className={styles.good}>3%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
						</tr>
						<tr>
							<td>Quartz</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>11%</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-2%</td>
							<td className={styles.bad}>-3%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>11%</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-3%</td>
						</tr>
						<tr>
							<td>Taranite</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-3%</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-6%</td>
							<td>&ndash;</td>
							<td className={styles.good}>5%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-6%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
						</tr>
						<tr>
							<td>Titanium</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-1%</td>
							<td className={styles.good}>5%</td>
							<td className={styles.good}>3%</td>
							<td className={styles.bad}>-2%</td>
							<td>&ndash;</td>
							<td className={styles.good}>6%</td>
							<td className={styles.good}>13%</td>
							<td className={styles.good}>5%</td>
							<td className={styles.bad}>-1%</td>
							<td>&ndash;</td>
						</tr>
						<tr>
							<td>Tungsten</td>
							<td className={styles.good}>4%</td>
							<td>&ndash;</td>
							<td className={styles.good}>2%</td>
							<td>&ndash;</td>
							<td className={styles.bad}>-6%</td>
							<td className={styles.bad}>-5%</td>
							<td>&ndash;</td>
							<td className={styles.good}>9%</td>
							<td>&ndash;</td>
							<td>&ndash;</td>
							<td className={styles.good}>2%</td>
							<td>&ndash;</td>
						</tr>
					</tbody>
				</table>
			</div>
			<footer>
				<ul>
					<li className="bad">Unfavorable</li>
					<li className="good">Favorable</li>
				</ul>
			</footer>
		</Section>
	</Section>
);

export default Refining;
