import { NavLink as Link, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Section from 'components/Section/section';
import SubtitledImage from 'components/SubtitledImage/subtitled-image';

import Refining from './Refining/refining';
import Selling from './Selling/selling';

import SellingCommodity from 'sharedAssets/sellingCommodity.jpg';
import RefiningOre from 'sharedAssets/refiningOre.jpg';

import styles from './post-mining.module.scss';

const LandingPage = () => (
	<>
		<Helmet>
			<link rel="canonical" href="https://www.scminingmom.com/post-mining" />
			<title>Star Citizen Mining Mom - Refining/Selling</title>
		</Helmet>
		<Section>
			<header>
				<h2>Refining &amp; Selling Overview</h2>
			</header>
		</Section>
		<Section className={styles.methodGrid}>
			<SubtitledImage href="refining" src={RefiningOre} reverse>
				<h2>Refining</h2>
				<div>
					<p>
						If you click <Link to="refining">Refining</Link> above, you will be
						able to see the different refining methods available, the best
						places to refine for your preferred resources and all of the
						refining stations within the Stanton System!
					</p>
				</div>
			</SubtitledImage>
			<SubtitledImage href="selling" src={SellingCommodity} reverse>
				<h2>Selling</h2>
				<div>
					<p>
						The <Link to="selling">Selling</Link> tab above will show you where
						you can go to sell your gems, raw ore and refined ores, so you can
						be on your way to optimal mining profits, with a list of all the
						admin offices!
					</p>
				</div>
			</SubtitledImage>
		</Section>
	</>
);

const PostMining = () => (
	<Routes>
		<Route index path="/" element={<LandingPage />} />
		<Route path="refining" element={<Refining />} />
		<Route path="selling" element={<Selling />} />
	</Routes>
);

PostMining.Navigation = () => (
	<ul>
		<li>
			<Link to="refining">Refining</Link>
		</li>
		<li>
			<Link to="selling">Selling</Link>
		</li>
	</ul>
);

export default PostMining;
