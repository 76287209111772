import Section from 'components/Section/section';
import styles from './selling.module.scss';

const TerminalLocations = () => (
	<Section className={styles.inlineChildren} horizontal panel>
		<header>
			<h4>Terminal Locations</h4>
		</header>
		<table className={styles.fillTable}>
			<tbody>
				<tr>
					<td>
						<h5>ArcCorp</h5>
					</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Baijini Point</td>
				</tr>
				<tr>
					<td>Lyria</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Humboldt Mines</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Loveridge Mineral Reserve</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Shubin Mining Facility SAL-2</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Shubin Mining Facility SAL-5</td>
				</tr>
				<tr>
					<td>Wala</td>
				</tr>
				<tr>
					<td className={styles.highlight}>ArcCorp Mining Area 045</td>
				</tr>
				<tr>
					<td className={styles.highlight}>ArcCorp Mining Area 048</td>
				</tr>
				<tr>
					<td className={styles.highlight}>ArcCorp Mining Area 056</td>
				</tr>
				<tr>
					<td className={styles.highlight}>ArcCorp Mining Area 061</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Samson & Son's Salvage Center</td>
				</tr>
				<tr>
					<td>Lagrange Points</td>
				</tr>
				<tr>
					<td className={styles.highlight}>ARC-L1</td>
				</tr>
			</tbody>
		</table>
		<table className={`${styles.fillTable} panel`}>
			<tbody>
				<tr>
					<td>
						<h5>Crusader</h5>
					</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Seraphim Station</td>
				</tr>
				<tr>
					<td>Cellin</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Gallete Family Farms</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Hickes Research Outpost</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Terra Mills HydroFarm</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Tram & Myers Mining</td>
				</tr>
				<tr>
					<td>Daymar</td>
				</tr>
				<tr>
					<td className={styles.highlight}>ArcCorp Mining Area 141</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Bountiful Harvest Hydroponics</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Brio's Breaker Yard</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Kudre Ore</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Shubin Mining Facility SCD-1</td>
				</tr>
				<tr>
					<td>Yela</td>
				</tr>
				<tr>
					<td className={styles.highlight}>ArcCorp Mining Area 157</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Benson Mining Outpost</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Deakins Research Outpost</td>
				</tr>
				<tr>
					<td className={styles.highlight}>GrimHEX</td>
				</tr>
				<tr>
					<td>Lagrange Points</td>
				</tr>
				<tr>
					<td className={styles.highlight}>CRU-L1</td>
				</tr>
				<tr>
					<td className={styles.highlight}>CRU-L4</td>
				</tr>
				<tr>
					<td className={styles.highlight}>CRU-L5</td>
				</tr>
			</tbody>
		</table>
		<table className={`${styles.fillTable} panel`}>
			<tbody>
				<tr>
					<td>
						<h5>Hurston</h5>
					</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Edmond</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Hadley</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Oparei</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Pinewood</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Stanhope</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Thedus</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Lorville</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Reclamation & Disposal Orinth</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Everus Harbor</td>
				</tr>
				<tr>
					<td>Aberdeen</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Anderson</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Norgaard</td>
				</tr>
				<tr>
					<td>Arial</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Bezdek</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Lathan</td>
				</tr>
				<tr>
					<td>Ita</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Ryder</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Woodruff</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Magda HDMS-Hahn</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HDMS-Perlman</td>
				</tr>
				<tr>
					<td>Lagrange Points</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HUR-L1</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HUR-L2</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HUR-L3</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HUR-L4</td>
				</tr>
				<tr>
					<td className={styles.highlight}>HUR-L5</td>
				</tr>
			</tbody>
		</table>
		<table className={`${styles.fillTable} panel`}>
			<tbody>
				<tr>
					<td>
						<h5>MicroTech</h5>
					</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Rayari Deltana Research Outpost</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Shubin Mining Facility SM0-10</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Shubin Mining Facility SM0-13</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Shubin Mining Facility SM0-18</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Shubin Mining Facility SM0-22</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Port Tressler</td>
				</tr>
				<tr>
					<td>Calliope</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Raven's Roost</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Rayari Anvik Research Outpost</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Rayari Kaltag Research Outpost</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Shubin Mining Facility SMCa-6</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Shubin Mining Facility SMCa-8</td>
				</tr>
				<tr>
					<td>Clio</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Rayari Cantwell Research Outpost</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Rayari McGrath Research Outpost</td>
				</tr>
				<tr>
					<td>Euterpe</td>
				</tr>
				<tr>
					<td className={styles.highlight}>Bud's Growery</td>
				</tr>
				<tr>
					<td>Lagrange Points</td>
				</tr>
				<tr>
					<td className={styles.highlight}>MIC-L1</td>
				</tr>
			</tbody>
		</table>
	</Section>
);

export default TerminalLocations;
