import { Helmet } from 'react-helmet-async';
import Section from 'components/Section/section';
import { Routes, Route } from 'react-router-dom';
import Asteroids from './Asteroids/asteroids';
import Deposits from './Deposits/deposits';
import Gemstones from './Gemstones/gemstones';

import styles from './locations.module.scss';

const Locations = () => {
	return (
		<Section className={styles.layout}>
			<Helmet>
				<link
					rel="canonical"
					href="https://www.scminingmom.com/mining/locations"
				/>
				<title>Star Citizen Mining Mom - Mining Locations</title>
			</Helmet>
			<Routes>
				<Route path="asteroids" element={<Asteroids />} />
				<Route path="deposits" element={<Deposits />} />
				<Route path="gemstones" element={<Gemstones />} />
			</Routes>
		</Section>
	);
};

export default Locations;
