import { NavLink as Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import HorizontalCards from 'components/HorizontalCards/cards';
import Key from 'components/Key/key';

import Section from 'components/Section/section';

import HTAH01 from './HTAH01.jpg';
import HTAH02 from './HTAH02.jpg';
import HTAH03 from './HTAH03.jpg';
import HTAH04 from './HTAH04.jpg';
import HTAH05 from './HTAH05.jpg';
import HTAH06 from './HTAH06.jpg';
import HTAH07 from './HTAH07.jpg';
import HTAH08 from './HTAH08.jpg';
import HTAH09 from './HTAH09.jpg';
import HTAH10 from './HTAH10.jpg';
import HTAH14 from './HTAH14.jpg';
import HTAH15 from './HTAH15.jpg';

import HTPS01 from './HTPS01.jpg';
import HTPS02 from './HTPS02.jpg';
import HTPS04 from './HTPS04.jpg';
import HTPS05 from './HTPS05.jpg';
import HTPS06 from './HTPS06.jpg';
import HTPS08 from './HTPS08.jpg';
import HTPS09 from './HTPS09.jpg';
import HTPS10 from './HTPS10.jpg';
import HTPS15 from './HTPS15.jpg';
import HTPS16 from './HTPS16.jpg';

import styles from '../subpage.module.scss';

const UEC = new Intl.NumberFormat(undefined, {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
	style: 'decimal',
});

const Ship = () => (
	<div className={styles.layout}>
		<Helmet>
			<link
				rel="canonical"
				href="https://www.scminingmom.com/mining/methods/prospector"
			/>
			<title>Star Citizen Mining Mom - Prospector Mining</title>
		</Helmet>
		<Section padded>
			<header>
				<h2>MISC Prospector</h2>
			</header>
			<p>
				For years, the Prospector has been Citizen's preferred mining vessel for
				solo operators. With an upgradable mining laser, you won't find an
				asteroid or deposit you can't mine! With 32 SCU carrying capacity,
				you're able to mine and collect the perfect amount of ore!
			</p>
		</Section>
		<Section padded>
			<header>
				<h3>Prospector How to</h3>
			</header>
			<p>
				The easiest thing to do with your recently rented prospector will be to
				go look for some asteroids! Why asteroids? Because asteroids are easily
				broken with the stock laser and doesn't require much start up cost.
				Following the Prospector Mining Guide the asteroids you'll want to look
				for using the recommended methods are ones containing Laranite,
				Hephaestanite, or Bexalite. Any of the asteroids that say "Easy" will be
				better suited for beginners. Once you have a full Prospector worth of
				materials, you can either refine (cost some aUEC) or sell the raw ore.
				Refined ore has a higher pay, raw ore is half the refined pay!
			</p>
		</Section>
		<Section padded>
			<header>
				<h3>Aaron Halo Mining How to</h3>
			</header>
		</Section>
		<HorizontalCards numbered>
			<HorizontalCards.Card img={HTAH01}>
				Plot your destination
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH02}>
				Once out of QT, press <Key>V</Key> to bring out your scanner
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH03}>
				Use your mouse wheel to adjust your scanning FOV to 180 degrees
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH04}>
				Hit <Key>Tab</Key> to ping
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH05}>
				When you're 12 km away from a cube, use your mouse wheel to set your FOV
				to 2 degrees
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH06}>
				Hit <Key>Tab</Key> again and a small white diamond (Or multiples) will
				show up
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH07}>
				Hold your left mouse button to start scanning
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH08}>
				Wait until it scans the asteroid type, head towards it and you can start
				to scan again to get more details about the asteroid
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH09}>
				When you are within 5 m of the rock, hit <Key>M</Key> on your keyboard,
				press your left mouse button to start your laser
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH10}>
				Use your mouse wheel to adjust your laser power to get the charge into
				the (green) optimal zone. When doing this, you'll want to hit the sweet
				spot, you'll know you hit it when you see lots of sparks, the more
				sparks, the better!
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hold in the optimal until the asteroid breaks
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Continue steps 9 through 11 adjusting your laser power accordingly until
				all of the asteroid breaks
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hit your right mouse button to bring out your extractor
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH14}>
				Point your extractor at each broken piece (Will show with a purple
				outline) to see what you will be collecting
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTAH15}>
				Once everything has been scanned, hit your left mouse button to start
				extracting
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Head back to the refinery of your choice
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Once at the refinery, select your preferred refinery method
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Repeat steps 1 through 17, make profit!
			</HorizontalCards.Card>
		</HorizontalCards>
		<Section padded>
			<header>
				<h3>Planet Side Mining How to</h3>
			</header>
		</Section>
		<HorizontalCards numbered>
			<HorizontalCards.Card img={HTPS01}>
				Plot your destination
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS02}>
				Once out of QT, press <Key>V</Key> to bring out your scanner
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Use your mouse wheel to adjust your scanning FOV to 180 degrees
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS04}>
				Hit <Key>Tab</Key> to ping
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS05}>
				When you're 12 km away from a cube, use your mouse wheel to set your FOV
				to 2 degrees
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS06}>
				Hit <Key>Tab</Key> again and a small white diamond (Or multiples) will
				show up
			</HorizontalCards.Card>
			<HorizontalCards.Card>Head towards the diamond</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS08}>
				Once closer to the white diamond, hit <Key>M</Key> to bring out your
				mining laser
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS09}>
				Get closer until the laser scans and registers what is in the deposit
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS10}>
				When you are within 5 m of the rock, press your left mouse button to
				start your laser
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Use your mouse wheel to adjust your laser power to get the charge into
				the (green) optimal zone. When doing this, you'll want to hit the sweet
				spot, you'll know you hit it when you see lots of sparks, the more
				sparks, the better!
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hold in the optimal until the deposit breaks
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Continue steps 9 through 11 adjusting your laser power accordingly until
				the deposit breaks
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Hit your right mouse button to bring out your extractor
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS15}>
				Point your extractor at each broken piece to see what you will be
				collecting (Collectables will show with a purple outline)
			</HorizontalCards.Card>
			<HorizontalCards.Card img={HTPS16}>
				Once everything has been scanned, hit your left mouse button to start
				extracting
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Head back to the refinery of your choice
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Once at the refinery, select your preferred refinery method
			</HorizontalCards.Card>
			<HorizontalCards.Card>
				Repeat steps 1 through 18, make profit!
			</HorizontalCards.Card>
		</HorizontalCards>
		<Section padded>
			<p>
				Woohoo! You've earned roughly {UEC.format(2100000)} aUEC (Ship and extra
				for a mining laser) and purchased a shiny new Prospector and you're
				ready to head out! But, wait! Did you grab a new mining laser for it?
				Depending on what you're mining and where, there are a number of options
				for you <Link to="/mining/upgrades?Size=1">listed here</Link>.
			</p>
		</Section>
		<Section padded>
			<header>
				<h3>Where to Go</h3>
			</header>
			<p>
				There is surface mining, which is done on a planet or a moon. Or there
				is asteroid mining, which is done in the Aaron Halo. Both will provide
				you with tons of options for mining materials. When surface mining,
				you'll come across what are called deposits. When mining in the Aaron
				Halo, you'll come across what are called asteroids.
			</p>
			<p>
				<Link to="/mining/locations/asteroids">See our locations page!</Link>
			</p>
		</Section>
		<Section padded>
			<header>
				<h3>Where to Buy</h3>
			</header>
			<p>
				<a href="https://robertsspaceindustries.com/pledge/ships/misc-prospector/Prospector#buying-options">
					$155.00 USD - Pledge Store
				</a>
			</p>
			<p>{UEC.format(2929500)} aUEC - Lorville - New Deal</p>
		</Section>
		<Section padded>
			<header>
				<h3>Where to Rent</h3>
			</header>
			<p>Refinery Stations at the rental terminal on the Refinery Deck</p>
		</Section>
	</div>
);

export default Ship;
