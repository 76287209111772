import pricing from 'data/pricing.json';

import Aphorite from './aphorite.png';
import Dolivine from './dolivine.png';
import Hadanite from './hadanite.png';

import styles from './gem-stone-info.module.scss';
import Section from 'components/Section/section';

const UEC = new Intl.NumberFormat(undefined, {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
	style: 'decimal',
});

const GemStoneInfo = ({ method }) => (
	<Section className={styles.types}>
		<p>
			There are 3 gem types you can mine with your{' '}
			{
				{
					multi: 'multi-tool and mining attachment',
					roc: 'Greycat Industrial ROC',
					undefined:
						'multi-tool and mining attachment or Greycat Industrial ROC',
				}[method]
			}
			. Most often these deposits are found in caves and or on the surface of
			planets or moons. (Gems can also be found attached to asteroids in the
			Aaron Halo.)
		</p>
		<ul>
			<li>
				<img
					alt="Aphorite - A blue mineral deposit that currently sells for 152.50 aUEC a piece"
					src={Aphorite}
				/>
				<h3 className={styles.aphorite}>Aphorite</h3>
				<dl>
					<dt>Sells For</dt>
					<dd>
						{UEC.format(
							pricing['9b47bacf-8efa-42e2-8d84-dee64983a00a'].average,
						)}{' '}
						aUEC
					</dd>

					<dt>Normal Signature</dt>
					<dd>120</dd>

					<dt>Large Signature</dt>
					<dd>620</dd>

					<dt>Asteroid Signature</dt>
					<dd>120</dd>
				</dl>
			</li>
			<li>
				<img
					alt="Dolivine - A green mineral deposit that currently sells for 130 aUEC a piece."
					src={Dolivine}
				/>
				<h3 className={styles.dolivine}>Dolivine</h3>
				<dl>
					<dt>Sells For</dt>
					<dd>
						{UEC.format(
							pricing['7e86e2f4-6c31-41f8-9438-0fd8ad037426'].average,
						)}{' '}
						aUEC
					</dd>

					<dt>Normal Signature</dt>
					<dd>120</dd>

					<dt>Large Signature</dt>
					<dd>620</dd>
				</dl>
			</li>
			<li>
				<img
					alt="Hadanite - A pinkish, purple mineral deposit that currently sells for 275 aUEC a piece"
					src={Hadanite}
				/>
				<h3 className={styles.hadanite}>Hadanite</h3>
				<dl>
					<dt>Sells For</dt>
					<dd>
						{UEC.format(
							pricing['3e5fdc37-cb59-4fd3-8168-e3c538ab9722'].average,
						)}{' '}
						aUEC
					</dd>

					<dt>Normal Signature</dt>
					<dd>120</dd>

					<dt>Large Signature</dt>
					<dd>620</dd>
				</dl>
			</li>
			<li>
				<img alt="Janalite - It's yellow" src={Hadanite} />
				<h3 className={styles.janalite}>Janalite</h3>
				<dl>
					<dt>Sells For</dt>
					<dd>
						{UEC.format(
							pricing['f3379bda-0520-4c75-920a-c29a50eb6b50'].average,
						)}{' '}
						aUEC
					</dd>

					<dt>Normal Signature</dt>
					<dd>120</dd>

					<dt>Large Signature</dt>
					<dd>620</dd>
				</dl>
			</li>
		</ul>
	</Section>
);

export const Locations = () => (
	<section className={styles.layout}>
		<header>
			<h3>Gemstone Locations</h3>
		</header>
		<div className="overflow">
			<table>
				<thead>
					<tr>
						<th />
						<th>
							<img className={styles.gemImage} src={Aphorite} alt="" />
							<h3 className={styles.aphorite}>Aphorite</h3>
						</th>
						<th>
							<img className={styles.gemImage} src={Dolivine} alt="" />
							<h3 className={styles.dolivine}>Dolivine</h3>
						</th>
						<th>
							<img className={styles.gemImage} src={Hadanite} alt="" />
							<h3 className={styles.hadanite}>Hadanite</h3>
						</th>
						<th>
							<img className={styles.gemImage} src={Hadanite} alt="" />
							<h3 className={styles.janalite}>Janalite</h3>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<strong>ArcCorp</strong>
						</td>
						<td colSpan="4" />
					</tr>
					<tr>
						<td>
							<span>Lyria</span>
						</td>
						<td className={styles.common}>★</td>
						<td className={styles.common}>★</td>
						<td className={styles.uncommon}>★</td>
						<td></td>
					</tr>
					<tr>
						<td>
							<span>Wala</span>
						</td>
						<td className={styles.veryCommon}>★</td>
						<td className={styles.common}>★</td>
						<td className={styles.uncommon}>★</td>
						<td>‡</td>
					</tr>
					<tr>
						<td colSpan="5" />
					</tr>
					<tr>
						<td>
							<strong>Crusader</strong>
						</td>
						<td colSpan="4" />
					</tr>
					<tr>
						<td>
							<span>Cellin</span>
						</td>
						<td className={styles.veryCommon}>★</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.uncommon}>★</td>
						<td></td>
					</tr>
					<tr>
						<td>
							<span>Daymar</span>
						</td>
						<td className={styles.common}>★</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.common}>★</td>
						<td>‡</td>
					</tr>
					<tr>
						<td>
							<span>Yela</span>
						</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.common}>★</td>
						<td className={styles.uncommon}>★</td>
						<td></td>
					</tr>
					<tr>
						<td colSpan="5" />
					</tr>
					<tr>
						<td>
							<strong>Hurston</strong>
						</td>
						<td className={styles.common}>★</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.uncommon}>★</td>
						<td></td>
					</tr>
					<tr>
						<td>
							<span>Arial</span>
						</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.common}>★</td>
						<td></td>
					</tr>
					<tr>
						<td>
							<span>Aberdeen</span>
						</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.veryCommon}>★</td>
						<td></td>
					</tr>
					<tr>
						<td>
							<span>Magda</span>
						</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.common}>★</td>
						<td className={styles.common}>★</td>
						<td>‡</td>
					</tr>
					<tr>
						<td>
							<span>Ita</span>
						</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.common}>★</td>
						<td className={styles.common}>★</td>
						<td>‡</td>
					</tr>
					<tr>
						<td colSpan="5" />
					</tr>
					<tr>
						<td>
							<strong>Microtech</strong>
						</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.common}>★</td>
						<td className={styles.uncommon}>★</td>
						<td></td>
					</tr>
					<tr>
						<td>
							<span>Calliope</span>
						</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.veryCommon}>★</td>
						<td className={styles.uncommon}>★</td>
						<td></td>
					</tr>
					<tr>
						<td>
							<span>Clio</span>
						</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.common}>★</td>
						<td className={styles.uncommon}>★</td>
						<td></td>
					</tr>
					<tr>
						<td>
							<span>Euterpe</span>
						</td>
						<td className={styles.uncommon}>★</td>
						<td className={styles.veryCommon}>★</td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>
		<footer>
			<ul>
				<li className={styles.veryCommon}>Very Common</li>
				<li className={styles.common}>Common</li>
				<li className={styles.uncommon}>Uncommon</li>
			</ul>
			<ul>
				<li>‡ = Underground Sand Caves (Very Rare)</li>
			</ul>
		</footer>
	</section>
);

export default GemStoneInfo;
