import { Children } from 'react';

import { NavLink as Link } from 'react-router-dom';

import Conditional from 'components/Conditional/conditional';

import styles from './subtitled-image.module.scss';

const SubtitledImage = ({ reverse = false, children, href, ...rest }) => (
	<figure
		className={[styles.subTitledImage, ...(reverse ? ['reverse'] : [])].join(
			' ',
		)}
	>
		<div className={styles.imageContainer}>
			<Conditional condition={!!href} component={Link} to={href}>
				<img alt="" {...rest} />
			</Conditional>
		</div>
		{Children.count(children) ? (
			<figcaption className={styles.text}>{children}</figcaption>
		) : null}
	</figure>
);

export default SubtitledImage;
