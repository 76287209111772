import React from 'react';
import ReactDOM from 'react-dom/client';

import { Main } from 'App/app';

import './index.scss';

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<Main />
	</React.StrictMode>,
);
