import { Helmet } from 'react-helmet-async';

import Section from 'components/Section/section';

import AdminOffices from './admin-offices';
import CommoditySales from './commodity-sales';
import OreSales from './ore-sales';
import TerminalLocations from './terminal-locations';

import styles from './selling.module.scss';

const Selling = () => (
	<>
		<Helmet>
			<link rel="canonical" href="https://www.scminingmom.com/selling" />
			<title>Star Citizen Mining Mom - Selling</title>
		</Helmet>
		<Section>
			<header>
				<h2>Selling</h2>
			</header>
		</Section>
		<Section className={styles.methodGrid}>
			<div>
				<AdminOffices />
			</div>
			<div>
				<OreSales />
			</div>
			<div>
				<CommoditySales />
			</div>
		</Section>
		<TerminalLocations />
	</>
);

export default Selling;
