import { NavLink as Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Section from 'components/Section/section';

import HorizontalCards from 'components/HorizontalCards/cards';
import Key from 'components/Key/key';

import OutpostTerminal from 'sharedAssets/outpost_terminal.jpg';

import HTHM03 from './HTHM03.jpg';
import HTHM04 from './HTHM04.jpg';
import HTHM05 from './HTHM05.jpg';
import HTHM07 from './HTHM07.jpg';
import HTHM09 from './HTHM09.jpg';

import styles from '../subpage.module.scss';

const UEC = new Intl.NumberFormat(undefined, {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
	style: 'decimal',
});

const Hand = () => {
	return (
		<div className={styles.layout}>
			<Helmet>
				<link
					rel="canonical"
					href="https://www.scminingmom.com/mining/methods/multi"
				/>
				<title>Star Citizen Mining Mom - Greycat Pyro Multi-Tool Mining</title>
			</Helmet>
			<Section padded>
				<header>
					<h2>Greycat Pyro Multi-Tool w/ OreBit Mining Attachment</h2>
				</header>
				<p>
					This mining method is often referred to as “FPS Mining” or “Hand
					Mining” and doesn't require a dedicated mining vessel such as a ROC,
					Prospector or MOLE. Hand mining or FPS mining is one of the easier
					ways for a new miner to get the hang of mining with minor
					repercussions if you do end up going into the overcharge bar.
				</p>
				<p>
					When hand mining, you'll be breaking small deposits that give gems and
					you'll put them into your backpack. These gems don't require a
					refinery and can be sold straight out of your backpack with multiple
					options for places to sell!
				</p>
			</Section>
			<Section padded>
				<header>
					<h3>Multi-Tool How to</h3>
				</header>
			</Section>
			<HorizontalCards numbered>
				<HorizontalCards.Card>
					Press <Key>4</Key> to arm yourself with your multitool
				</HorizontalCards.Card>
				<HorizontalCards.Card>
					Press the right mouse button to bring up the “aim” of your multitool
				</HorizontalCards.Card>
				<HorizontalCards.Card img={HTHM03}>
					Point your multitool at a deposit to begin scanning (Scanning will
					show on the right side of your multitool screen)
				</HorizontalCards.Card>
				<HorizontalCards.Card img={HTHM04}>
					Once scanning has finished, press the left mouse button to start the
					mining laser
				</HorizontalCards.Card>
				<HorizontalCards.Card img={HTHM05}>
					Use the mouse wheel to increase or decrease your laser power, trying
					to keep your charge level in the optimal zone. (The heat level will be
					appear on the left side of your multitool and will turn green when
					you're in the optimal zone, red when you've hit overcharge)
				</HorizontalCards.Card>
				<HorizontalCards.Card>
					Now that you've broken the deposit, you'll find little gems scattered
					on the ground in front of you, hold <Key>R</Key> to put your multitool
					away.
				</HorizontalCards.Card>
				<HorizontalCards.Card img={HTHM07}>
					Look at the gem you wish to grab and hit <Key>F</Key> to pick it up
					and store it in your backpack, keep doing this until you have picked
					up each piece on the ground.
				</HorizontalCards.Card>
				<HorizontalCards.Card img={OutpostTerminal}>
					Head to an Admin office of your choice
				</HorizontalCards.Card>
				<HorizontalCards.Card img={HTHM09}>
					Sell from your backpack and make Profit!
				</HorizontalCards.Card>
			</HorizontalCards>

			<Section padded>
				<p>
					Now that you've earned roughly {UEC.format(84017)} aUEC (
					{UEC.format(72729)} to rent a Cutlass Black for 3 days and{' '}
					{UEC.format(11288)} to rent a Greycat ROC for 3 days) You'll want to
					go and rent both of those from a refinery station! Now, You're ready
					to move on to <Link to="/mining/methods/roc">ROC mining</Link>!
				</p>
			</Section>
			<Section padded>
				<header>
					<h3>Gear / Items</h3>
				</header>
				<p>
					For hand mining, you're going to want to purchase, (or go loot at some
					mining outposts around the Stanton System), the following;
				</p>
				<ul>
					<li>A light, medium or heavy torso that is backpack compatible.</li>
					<li>A light, medium or heavy backpack</li>
					<li>
						A Greycat Pyro Multi Tool - A handheld tool with multiple attachment
						options that enable the user to use it for a wide variety of tasks.
					</li>
					<li>
						An OreBit Mining Attachment - Allows the user to scan and mine small
						mineral deposits.
					</li>
					<li>
						A TruHold Tractor Beam Attachment - Allows the user to remotely move
						hard to reach objects, smaller cargo or can be used to pull the user
						towards large immovable objects.
					</li>
				</ul>
				<p>
					My favourite place to pick up these items is Cubby Blast in Area 18 on
					ArcCorp!
				</p>
			</Section>
			<Section padded>
				<header>
					<h3>Where to Go</h3>
				</header>
				<p>
					The easiest places to find Hand Minable gems are in caves! There are a
					number of caves throughout the Stanton System. There are some marked
					caves such as; Hurston - HDMO - Clathrope and on Aberdeen - HDMO -
					Dobbs, other caves you may need to check the Investigation tab in the
					Missions section of your MobiGlass, for “Investigate Missing Client”
					Not all of the investigation missions will lead you to a cave, so be
					sure to read the mission before accepting. Be careful landing here,
					there are often NPCs that spawn in these areas and are likely to
					attempt (or successfully) to blow up your ship.
				</p>
			</Section>
		</div>
	);
};

export default Hand;
